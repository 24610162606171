import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Link,
  Typography,
  Button,
  Grow,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


interface BreadcrumbsProps {
  contents: {title: string, link: string}[];
}

function Breadcrumbs({
  contents
}: BreadcrumbsProps) {
  return (
    <div>
      {contents.map((item: any, i: number) => {
        return (
          <div style={{display: 'inline-block'}}>
            <Button
              size="small"
              onClick={() => {
                if (item.link) window.location = item.link;
              }}
            >{item.title}</Button>
            {(i < (contents.length - 1)) &&
              <ChevronRightIcon fontSize="small" style={{verticalAlign: 'middle'}} />
            }
          </div>
        )
      })}
    </div>
  );
}

export default Breadcrumbs;