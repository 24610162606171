
import letterSpaceAdjustment from './letterSpaceAdjustment';
import mainLogo512 from '../img/logo512.png';


interface CanvasOption {
  main: string;
  part1: string;
  part2: string;
  part3: string;
  overlay_shadow: string;
  overlay_shadow_cropped: string;
}

let logo512 = ((window as any).APP_CONFIG && (window as any).APP_CONFIG.siteLogo) ? (window as any).APP_CONFIG.siteLogo : mainLogo512;

const defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';
const palleteColors = ["#ff00ff", "#ff0000", "#00ff00", "#0000ff", "#00ffff" ];
let layersImgCached: any = {};

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result ? parseInt(result[1], 16) : 255,
    g: result ? parseInt(result[2], 16) : 255,
    b: result ? parseInt(result[3], 16): 255
  };
}

function hexToRgbAlt(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result ? parseInt(result[1], 16)/255 : 1,
    g: result ? parseInt(result[2], 16)/255 : 1,
    b: result ? parseInt(result[3], 16)/255: 1
  };
}

interface ColorsOption {
  original: { r: number; g: number; b: number; }[],
  current: { r: number; g: number; b: number; }[]
}

let recolorTexture = function (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: ColorsOption) {
  let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let data = imgData.data;
  let tolerance = 128;

  if (data && data.length) {
    for (let i = 0; i < data.length; i += 4) {
        const red = data[i + 0];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        let newcolor = null;
        let deltaR = 0;
        let deltaG = 0;
        let deltaB = 0;
        for (let c = 0; c < colors.original.length; c++) {
          let original = colors.original[c];
          let current = colors.current[c];
          if ((red <= (original.r + tolerance)) && (red >= (original.r - tolerance))) {
            if ((green <= (original.g + tolerance)) && (green >= (original.g - tolerance))) {
              if ((blue <= (original.b + tolerance)) && (blue >= (original.b - tolerance))) {
                newcolor = current;
                deltaR = red - original.r;
                deltaG = green - original.g;
                deltaB = blue - original.b;
                break;
              }
            }
          }
        }
        
        // change blueish pixels to the new color
        if (newcolor) {
          data[i + 0] = newcolor.r + (deltaR + deltaG + deltaB) / 3;
          data[i + 1] = newcolor.g + (deltaR + deltaG + deltaB) / 3;
          data[i + 2] = newcolor.b + (deltaR + deltaG + deltaB) / 3;
          data[i + 3] = alpha;
        }
        if (alpha < 100) {
          data[i + 3] = Math.sqrt(alpha); // hide transparent color as they're mostly unused in the template
        }
    }
  }
  ctx.putImageData(imgData, 0, 0);
}

let cropOverlayShadow = function (overlayCanvas: HTMLCanvasElement, overlayCtx: CanvasRenderingContext2D, targetCanvas: HTMLCanvasElement, targetCtx: CanvasRenderingContext2D, maskCanvas: HTMLCanvasElement, maskCtx: CanvasRenderingContext2D, xOffset=0, yOffset=0) {
  let overlay = overlayCtx.getImageData(0, 0, overlayCanvas.width, overlayCanvas.height);
  let mask = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
  let overlayData = overlay.data;
  let maskData = mask.data;

  if (maskData && maskData.length) {
    for (let i = 0; i < maskData.length; i += 4) {
        const red = maskData[i + 0];
        const green = maskData[i + 1];
        const blue = maskData[i + 2];
        const alpha = maskData[i + 3];

        let newcolor = null;
        let deltaR = 0;
        let deltaG = 0;
        let deltaB = 0;
        if (alpha > 100) {
          overlayData[i + 3] = 0
        }
        else {
          let average = 255 - Math.sqrt((overlayData[i + 0] + overlayData[i + 1] + overlayData[i + 2]) / 3);
          if (average > 240) average = 230;
          overlayData[i + 0] = average;
          overlayData[i + 1] = average;
          overlayData[i + 2] = average;
        }
    }
  }
  targetCtx.putImageData(overlay, xOffset, yOffset);
}

let renderText = (design: Design, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, texts: any[], sourceAtop: boolean = true) => {
  texts.forEach((item: any, i: number) => {

    var _fillStyle = ctx.fillStyle;
    var _strokeStyle = ctx.strokeStyle;
    var _lineWidth = ctx.lineWidth;
    var _font = ctx.font;
    var _textAlign = ctx.textAlign;

    var fill_color = item.fill_color;
    var stroke_color = item.stroke_color;

    if (fill_color == 'color_1') fill_color = design.color_1;
    if (fill_color == 'color_2') fill_color = design.color_2;
    if (fill_color == 'color_3') fill_color = design.color_3;
    if (fill_color == 'color_4') fill_color = design.color_4;
    if (fill_color == 'color_5') fill_color = design.color_5;
    if (fill_color == 'text_color') {
      if (item.text_type == 'text_1') fill_color = design.text_fill_color_1;
      if (item.text_type == 'text_2') fill_color = design.text_fill_color_2;
      if (item.text_type == 'text_3') fill_color = design.text_fill_color_3;
      if (item.text_type == 'text_4') fill_color = design.text_fill_color_4;
      if (item.text_type == 'text_5') fill_color = design.text_fill_color_5;
      if (item.text_type == 'text_6') fill_color = design.text_fill_color_6;
    }
    
    if (stroke_color == 'color_1') stroke_color = design.color_1;
    if (stroke_color == 'color_2') stroke_color = design.color_2;
    if (stroke_color == 'color_3') stroke_color = design.color_3;
    if (stroke_color == 'color_4') stroke_color = design.color_4;
    if (stroke_color == 'color_5') stroke_color = design.color_5;
    if (stroke_color == 'text_stroke_color') {
      if (item.text_type == 'text_1') stroke_color = design.text_stroke_color_1;
      if (item.text_type == 'text_2') stroke_color = design.text_stroke_color_2;
      if (item.text_type == 'text_3') stroke_color = design.text_stroke_color_3;
      if (item.text_type == 'text_4') stroke_color = design.text_stroke_color_4;
      if (item.text_type == 'text_5') stroke_color = design.text_stroke_color_5;
      if (item.text_type == 'text_6') stroke_color = design.text_stroke_color_6;
    }

    ctx.fillStyle = fill_color;
    ctx.strokeStyle = stroke_color;
    ctx.lineWidth = item.line_width;

    let delta: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
    if (item.text_type == 'text_1') delta = Object.assign({}, delta, design.text_position_1);
    if (item.text_type == 'text_2') delta = Object.assign({}, delta, design.text_position_2);
    if (item.text_type == 'text_3') delta = Object.assign({}, delta, design.text_position_3);
    if (item.text_type == 'text_4') delta = Object.assign({}, delta, design.text_position_4);
    if (item.text_type == 'text_5') delta = Object.assign({}, delta, design.text_position_5);
    if (item.text_type == 'text_6') delta = Object.assign({}, delta, design.text_position_6);

    let fontSize = item.font_size + delta.h;

    let textContent = "";
    let letterSpacing = 0;
    if (item.text_type == 'text_1') {
      textContent = design.text_1 ? design.text_1 : (design.template_data?.text_default_1 ? design.template_data?.text_default_1 : '');
      letterSpacing = design.text_font_letter_spacing_1;
      if (design.text_font_id_1) {
        ctx.font = `bold ${fontSize}px "${design.text_font_name_1}"`;
      }
    }
    if (item.text_type == 'text_2') {
      textContent = design.text_2 ? design.text_2 : (design.template_data?.text_default_2 ? design.template_data?.text_default_2 : '');
      letterSpacing = design.text_font_letter_spacing_2;
      if (design.text_font_id_2) {
        ctx.font = `bold ${fontSize}px "${design.text_font_name_2}"`;
      }
    }
    if (item.text_type == 'text_3') {
      textContent = design.text_3 ? design.text_3 : (design.template_data?.text_default_3 ? design.template_data?.text_default_3 : '');
      letterSpacing = design.text_font_letter_spacing_3;
      if (design.text_font_id_3) {
        ctx.font = `bold ${fontSize}px "${design.text_font_name_3}"`;
      }
    }
    if (item.text_type == 'text_4') {
      textContent = design.text_4 ? design.text_4 : (design.template_data?.text_default_4 ? design.template_data?.text_default_4 : '');
      letterSpacing = design.text_font_letter_spacing_4;
      if (design.text_font_id_4) {
        ctx.font = `bold ${fontSize}px "${design.text_font_name_4}"`;
      }
    }
    if (item.text_type == 'text_5') {
      textContent = design.text_5 ? design.text_5 : (design.template_data?.text_default_5 ? design.template_data?.text_default_5 : '');
      letterSpacing = design.text_font_letter_spacing_5;
      if (design.text_font_id_5) {
        ctx.font = `bold ${fontSize}px "${design.text_font_name_5}"`;
      }
    }
    if (item.text_type == 'text_6') {
      textContent = design.text_6 ? design.text_6 : (design.template_data?.text_default_6 ? design.template_data?.text_default_6 : '');
      letterSpacing = design.text_font_letter_spacing_6;
      if (design.text_font_id_6) {
        ctx.font = `bold ${fontSize}px "${design.text_font_name_6}"`;
      }
    }
    if (item.text_type == 'custom') {
      textContent = item.custom_text;
    }

    let originalTextContent = textContent;
    textContent = letterSpaceAdjustment(textContent, letterSpacing);

    if (item.font_family) {
      let fontFamily = 'Arial';
      if (item.font_family === 'text_font_name_1' && design.text_font_name_1) fontFamily = design.text_font_name_1;
      if (item.font_family === 'text_font_name_2' && design.text_font_name_2) fontFamily = design.text_font_name_2;
      if (item.font_family === 'text_font_name_3' && design.text_font_name_3) fontFamily = design.text_font_name_3;
      if (item.font_family === 'text_font_name_4' && design.text_font_name_4) fontFamily = design.text_font_name_4;
      if (item.font_family === 'text_font_name_5' && design.text_font_name_5) fontFamily = design.text_font_name_5;
      if (item.font_family === 'text_font_name_6' && design.text_font_name_6) fontFamily = design.text_font_name_6;
      ctx.font = `bold ${fontSize}px "${fontFamily}"`;
    }
    else {
      ctx.font = `bold ${fontSize}px Arial`;
    }

    ctx.save();


    let effect = delta.effect ? delta.effect : '';
    let textDimension = ctx.measureText(textContent);
    let textWidth = textDimension.width;
    let textHeight = fontSize;

    if (item.text_align) ctx.textAlign = item.text_align;

    if (sourceAtop) ctx.globalCompositeOperation = "source-atop";
    ctx.translate(item.x + delta.x, item.y + delta.y);

    if (item.angle || delta.r) {
      ctx.translate(0, -(fontSize / 2));
      ctx.rotate(((item.angle ? item.angle : 0) + (delta.r ? delta.r : 0)) * Math.PI / 180);
      ctx.translate(0, (fontSize / 2));
    }

    if (fill_color != stroke_color) {
      ctx.fillStyle = fill_color;
      ctx.strokeStyle = stroke_color;
      if (effect === 'arc') {
        let r = (delta.effectOpt.arcIntensity !== undefined ? delta.effectOpt.arcIntensity : 50) / 50;
        let reverse = delta.effectOpt.reverseArc ? true : false;
        drawTextAlongArc('stroke', ctx, textContent, textWidth / 4, Math.PI * r, fontSize, reverse);
      }
      else if (effect === 'vertical') {
        drawTextVertical('stroke', ctx, originalTextContent, fontSize);
      }
      else {
        ctx.strokeText(textContent, 0, 0);
      }
    }

    ctx.fillStyle = fill_color;
    ctx.strokeStyle = stroke_color;
    if (effect === 'arc') {
      let r = (delta.effectOpt.arcIntensity !== undefined ? delta.effectOpt.arcIntensity : 50) / 50;
      let reverse = delta.effectOpt.reverseArc ? true : false;
      drawTextAlongArc('fill', ctx, textContent, textWidth / 4, Math.PI * r, fontSize, reverse);
    }
    else if (effect === 'vertical') {
      drawTextVertical('fill', ctx, originalTextContent, fontSize);
    }
    else {
      ctx.fillText(textContent, 0, 0);
    }

    ctx.globalCompositeOperation = "source-over";
    ctx.restore();

    ctx.textAlign = _textAlign;
    ctx.fillStyle = _fillStyle;
    ctx.strokeStyle = _strokeStyle;
    ctx.lineWidth = _lineWidth;
    ctx.font = _font;
  });
}

function drawTextVertical(mode: string, ctx: CanvasRenderingContext2D, text: string, fontSize: number) {
  let len = text.length;
  let s = '';
  ctx.save();
  ctx.translate(0, -1 * fontSize * (len / 2));
  for (let n = 0; n < len; n++) {
    ctx.translate(0, fontSize);
    s = text[n];
    if (mode === 'fill') ctx.fillText(s, 0, 0);
    if (mode === 'stroke') ctx.strokeText(s, 0, 0);
  }
  ctx.restore();
}

function drawTextAlongArc(mode: string, ctx: CanvasRenderingContext2D, text: string, radius: number, angle: number, fontSize: number, reverse?: boolean) {
  let len = text.length;
  let s = '';
  ctx.save();
  // ctx.translate(centerX, centerY);
  if (reverse) {
    radius += (radius - (radius * (angle / Math.PI))) * 2;
    ctx.translate(0, -1 * (radius - (radius * (angle / Math.PI))) * 1.5);
    ctx.rotate(angle / 2);
    ctx.rotate((angle / len) / 2);
    for (let n = 0; n < len; n++) {
      ctx.rotate(-1 * angle / len);
      ctx.save();
      ctx.translate(0, (1 * radius) + fontSize);
      s = text[n];
      if (mode === 'fill') ctx.fillText(s, 0, 0);
      if (mode === 'stroke') ctx.strokeText(s, 0, 0);
      ctx.restore();
    }
  }
  else {
    radius += (radius - (radius * (angle / Math.PI))) * 2;
    ctx.translate(0, (radius - (radius * (angle / Math.PI))) * 1.5);
    ctx.rotate(-1 * angle / 2);
    ctx.rotate(-1 * (angle / len) / 2);
    for (let n = 0; n < len; n++) {
      ctx.rotate(angle / len);
      ctx.save();
      ctx.translate(0, -1 * radius);
      s = text[n];
      if (mode === 'fill') ctx.fillText(s, 0, 0);
      if (mode === 'stroke') ctx.strokeText(s, 0, 0);
      ctx.restore();
    }
  }
  ctx.restore();
}

function customImageDrawer(ctx: CanvasRenderingContext2D, image: any, x: number, y: number, w: number, h: number, removeWhite?: boolean) {
  let width = w <= 1500 ? w : 1500;
  let height = h <= 1500 ? h : 1500;

  const imageCanvas = document.getElementById('design_image_editor') as HTMLCanvasElement;
  imageCanvas.width = width;
  imageCanvas.height = height;
  const imageCtx = imageCanvas.getContext("2d");
  if (!imageCtx) return;

  imageCtx.clearRect(0, 0, 1500, 1500);
  imageCtx.drawImage(
    image,
    0,
    0,
    width,
    height
  );

  if (removeWhite) {
    let imgData = imageCtx.getImageData(0, 0, imageCanvas.width, imageCanvas.height);
    let data = imgData.data;
    let tolerance = 10;

    if (data && data.length) {
      for (let i = 0; i < data.length; i += 4) {
        const red = data[i + 0];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        let newcolor: CustomColor|undefined = undefined;
        let original = {r: 255, g: 255, b: 255};
        if ((red <= (original.r + tolerance)) && (red >= (original.r - tolerance))) {
          if ((green <= (original.g + tolerance)) && (green >= (original.g - tolerance))) {
            if ((blue <= (original.b + tolerance)) && (blue >= (original.b - tolerance))) {
              newcolor = {r: 255, g: 255, b: 255};
            }
          }
        }
        
        if (newcolor) {
          data[i + 3] = 0;
        }
      }
    }

    imageCtx.putImageData(imgData, 0, 0);
  }

  ctx.drawImage(
    imageCanvas,
    x,
    y,
    width,
    height
  );

}

interface RenderResult {
  render: string;
  overlayShadow?: string;
}


export default async (design: Design, canvasOpt: CanvasOption): Promise<RenderResult> => {
  const mainCanvas = document.getElementById(canvasOpt.main) as HTMLCanvasElement;
  const mainCtx = mainCanvas.getContext("2d");
  if (!mainCanvas || !mainCtx) return {render: defaultImage, overlayShadow: undefined};

  const part1Canvas = document.getElementById(canvasOpt.part1) as HTMLCanvasElement;
  const part1Ctx = part1Canvas.getContext("2d");
  if (!part1Canvas || !part1Ctx) return {render: defaultImage, overlayShadow: undefined};

  const part2Canvas = document.getElementById(canvasOpt.part2) as HTMLCanvasElement;
  const part2Ctx = part2Canvas.getContext("2d");
  if (!part2Canvas || !part2Ctx) return {render: defaultImage, overlayShadow: undefined};

  const part3Canvas = document.getElementById(canvasOpt.part3) as HTMLCanvasElement;
  const part3Ctx = part3Canvas.getContext("2d");
  if (!part3Canvas || !part3Ctx) return {render: defaultImage, overlayShadow: undefined};

  const overlayShadowCanvas = document.getElementById(canvasOpt.overlay_shadow) as HTMLCanvasElement;
  const overlayShadowCtx = overlayShadowCanvas.getContext("2d");
  if (!overlayShadowCanvas || !overlayShadowCtx) return {render: defaultImage, overlayShadow: undefined};

  const overlayShadowCroppedCanvas = document.getElementById(canvasOpt.overlay_shadow_cropped) as HTMLCanvasElement;
  const overlayShadowCroppedCtx = overlayShadowCroppedCanvas.getContext("2d");
  if (!overlayShadowCroppedCanvas || !overlayShadowCroppedCtx) return {render: defaultImage, overlayShadow: undefined};


  return new Promise((resolve, reject) => {

    const startIfAllLoaded = () => {
      loadingCounter -= 1;
      if (loadingCounter == 0) {
        start();
      }
    }

    const renderLayer = (ctx: CanvasRenderingContext2D, template: Template, design: Design, cw: number, ch: number) => {
      // render base layer
      if (template.template) ctx.drawImage(layersImgCached[template.template], 0, 0, cw, ch);

      // recolor base layer
      let colors: ColorsOption = {
        original: [
          hexToRgb(palleteColors[0]),
          hexToRgb(palleteColors[1]),
          hexToRgb(palleteColors[2]),
          hexToRgb(palleteColors[3]),
          hexToRgb(palleteColors[4]),
        ],
        current: [
          hexToRgb(design.color_1),
          hexToRgb(design.color_2),
          hexToRgb(design.color_3),
          hexToRgb(design.color_4),
          hexToRgb(design.color_5),
        ],
      }
    };

    const renderLayerItems = (ctx: CanvasRenderingContext2D, template: Template, design: Design, cw: number, ch: number, sourceAtop: boolean = true) => {
      let templateTexts: any[] = [];
      if (template.template_texts) {
        template.template_texts.forEach(t => {
          templateTexts.push(t);
        });
      }
      [1,2,3,4,5,6].forEach((i) => {
        let name = `text_${i}`;
        let content = (design as any)[`text_${i}`];
        if (!content) return;
        let alreadyUsed = false;
        templateTexts.forEach((position) => {
          if (position.text_type == name) alreadyUsed = true;
        });
        
        if (!alreadyUsed) {
          let fontFamily = (design as any)[`text_font_name_${i}`];
          templateTexts.push({
            "x": cw / 2,
            "y": ch / 2,
            "font_size": 80,
            "font_family": fontFamily,
            "text_type": name,
            "fill_color": "text_color",
            "line_width": 8,
            "text_align": "center",
            "stroke_color": "text_stroke_color"
          });
        }
      });

      if (template.template_texts) {
        renderText(design, part1Canvas, ctx, templateTexts, sourceAtop);
        renderText(design, overlayShadowCanvas, overlayShadowCtx, templateTexts, false);
      }

      // render logo
      let templateLogos: any[] = [];
      if (template.template_logos) {
        template.template_logos.forEach((position, i) => {
          if (position.empty) return;
          let imageUrl = design.logo_1;
          if (position.image == 'logo-1') imageUrl = design.logo_1;
          if (position.image == 'logo-2') imageUrl = design.logo_2;
          if (position.image == 'logo-3') imageUrl = design.logo_3;
          if (position.image == 'logo-4') imageUrl = design.logo_4;
          if (position.image == 'logo-5') imageUrl = design.logo_5;

          if (!imageUrl) return;

          templateLogos.push(position);
        });
      }

      [1,2,3,4,5].forEach((i) => {
        let name = `logo-${i}`;
        let logo = (design as any)[`logo_${i}`];
        if (!logo) return;
        let alreadyUsed = false;
        templateLogos.forEach((position) => {
          if (position.image == name) alreadyUsed = true;
        });
        
        if (!alreadyUsed) {
          templateLogos.push({
            "w": cw / 6,
            "x": cw / 2 - (cw / 12),
            "y": ch / 2 - (ch / 12),
            "image": name
          });
        }
      });

      templateLogos.forEach((position, i) => {
        if (position.empty) return;
        let imageUrl = design.logo_1;
        if (position.image == 'logo-1') imageUrl = design.logo_1;
        if (position.image == 'logo-2') imageUrl = design.logo_2;
        if (position.image == 'logo-3') imageUrl = design.logo_3;
        if (position.image == 'logo-4') imageUrl = design.logo_4;
        if (position.image == 'logo-5') imageUrl = design.logo_5;

        if (!imageUrl) return;

        let image = layersImgCached[imageUrl];
        if (!image) {
          console.log('not found', position.image, imageUrl);
        }
        let delta: DesignObjectPosition = {x: 0, y: 0, w: 0};

        if (position.image === 'logo-1') delta = design.logo_position_1;
        if (position.image === 'logo-2') delta = design.logo_position_2;
        if (position.image === 'logo-3') delta = design.logo_position_3;
        if (position.image === 'logo-4') delta = design.logo_position_4;
        if (position.image === 'logo-5') delta = design.logo_position_5;

        ctx.save();
        overlayShadowCtx.save();

        if (sourceAtop) ctx.globalCompositeOperation = "source-atop";

        let x = position.x + (delta.x ? delta.x : 0) - ((delta.w ? delta.w : 0) / 2);
        let y = position.y + (delta.y ? delta.y : 0);
        let w = position.w + (delta.w ? delta.w : 0);
        let h = (w / image.width) * image.height;

        ctx.translate(x, y);
        overlayShadowCtx.translate(x, y);
        
        if (position.angle || delta.r) {
          ctx.translate(w/2, h/2);
          overlayShadowCtx.translate(w/2, h/2)
          let r = (((position.angle ? position.angle : 0) + (delta.r ? delta.r : 0)) * Math.PI / 180);
          ctx.rotate(r)
          overlayShadowCtx.rotate(r);
          ctx.translate(-(w/2), -(h/2));
          overlayShadowCtx.translate(-(w/2), -(h/2));
        }

        customImageDrawer(ctx, image, 0, 0, w, h, delta.removeWhite);
        customImageDrawer(overlayShadowCtx, image, 0, 0, w, h, delta.removeWhite);

        ctx.globalCompositeOperation = "source-over";
        ctx.restore();
        overlayShadowCtx.restore();
      });


      // render shading layer
      if (template.template_shading) ctx.drawImage(layersImgCached[template.template_shading], 0, 0, cw, ch);
    };

    const start = () => {
      if ((window as any).APP_CONFIG.noOverlappingWatermark) {
        mainCanvas.height = 1700;
        overlayShadowCanvas.height = 1700;
        overlayShadowCroppedCanvas.height = 1700;
      }

      mainCtx.clearRect(0, 0, mainCanvas.width, mainCanvas.height);
      part1Ctx.clearRect(0, 0, part1Canvas.width, part1Canvas.height);
      overlayShadowCtx.clearRect(0, 0, overlayShadowCanvas.width, overlayShadowCanvas.height);

      if (!design.template_data) return;

      let subpartSelected = false;
      let renderMainLayer = true;

      if (design.template_data.skip_main_renderer_when_subpart_selected) {
        if ((design.template_data.number_of_subparts > 0) && (design.subpart_1)) {
          renderMainLayer = false;
        }
        if ((design.template_data.number_of_subparts > 1) && (design.subpart_2)) {
          renderMainLayer = false;
        }
        if ((design.template_data.number_of_subparts > 2) && (design.subpart_3)) {
          renderMainLayer = false;
        }
        if ((design.template_data.number_of_subparts > 3) && (design.subpart_4)) {
          renderMainLayer = false;
        }
        if ((design.template_data.number_of_subparts > 4) && (design.subpart_5)) {
          renderMainLayer = false;
        }
      }

      if (renderMainLayer) {
        // render main layer
        renderLayer(part1Ctx, design.template_data, design, part1Canvas.width, part1Canvas.height);
      }
      else {
        console.log('skipping main layer')
      }

      // render other layer
      if (design.template_data.number_of_subparts > 0) {
        design.template_data.subpart_1
          .filter(subpart => subpart.id === design.subpart_1)
          .forEach(subpart => {
            if (subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 1) {
        design.template_data.subpart_2
          .filter(subpart => subpart.id === design.subpart_2)
          .forEach(subpart => {
            if (subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 2) {
        design.template_data.subpart_3
          .filter(subpart => subpart.id === design.subpart_3)
          .forEach(subpart => {
            if (subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 3) {
        design.template_data.subpart_4
          .filter(subpart => subpart.id === design.subpart_4)
          .forEach(subpart => {
            if (subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 4) {
        design.template_data.subpart_5
          .filter(subpart => subpart.id === design.subpart_5)
          .forEach(subpart => {
            if (subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }

      // recolor base layer
      let colors: ColorsOption = {
        original: [
          hexToRgb(palleteColors[0]),
          hexToRgb(palleteColors[1]),
          hexToRgb(palleteColors[2]),
          hexToRgb(palleteColors[3]),
          hexToRgb(palleteColors[4]),
        ],
        current: [
          hexToRgb(design.color_1),
          hexToRgb(design.color_2),
          hexToRgb(design.color_3),
          hexToRgb(design.color_4),
          hexToRgb(design.color_5),
        ],
      }
      recolorTexture(part1Canvas, part1Ctx, colors);

      // render main layer items
      renderLayerItems(part1Ctx, design.template_data, design, part1Canvas.width, part1Canvas.height);

      // render other layer items
      if (design.template_data.number_of_subparts > 0) {
        design.template_data.subpart_1
          .filter(subpart => subpart.id === design.subpart_1)
          .forEach(subpart => {
            if (!subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
            renderLayerItems(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 1) {
        design.template_data.subpart_2
          .filter(subpart => subpart.id === design.subpart_2)
          .forEach(subpart => {
            if (!subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
            renderLayerItems(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 2) {
        design.template_data.subpart_3
          .filter(subpart => subpart.id === design.subpart_3)
          .forEach(subpart => {
            if (!subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
            renderLayerItems(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 3) {
        design.template_data.subpart_4
          .filter(subpart => subpart.id === design.subpart_4)
          .forEach(subpart => {
            if (!subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
            renderLayerItems(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }
      if (design.template_data.number_of_subparts > 4) {
        design.template_data.subpart_5
          .filter(subpart => subpart.id === design.subpart_5)
          .forEach(subpart => {
            if (!subpart.process_template_colors) renderLayer(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
            renderLayerItems(part1Ctx, subpart, design, part1Canvas.width, part1Canvas.height);
          })
      }

      if ((window as any).APP_CONFIG.noOverlappingWatermark) {
        // render final image
        mainCtx.rect(0, 0, mainCanvas.width, mainCanvas.height);
        mainCtx.fillStyle = 'white';
        mainCtx.fill();

        overlayShadowCroppedCtx.clearRect(0, 0, overlayShadowCroppedCanvas.width, overlayShadowCroppedCanvas.height);
        cropOverlayShadow(overlayShadowCanvas, overlayShadowCtx, overlayShadowCroppedCanvas, overlayShadowCroppedCtx, part1Canvas, part1Ctx, 0, 100);

        mainCtx.drawImage(part1Canvas, 0, 100);

        let designNameFontSize = 50;

        mainCtx.fillStyle = '#000000';
        mainCtx.strokeStyle = '#000000';
        mainCtx.lineWidth = 0
        mainCtx.font = "bold " + (designNameFontSize) + 'px "Arial"';
        mainCtx.textAlign = 'center';

        let designName = design.design_name;
        if (!designName) {
          designName = "[Your Design]";
        }

        designName = `${designName}`;
        let targetdesignNameWidth = mainCanvas.width - 100;

        let designNameTextMeasurement = mainCtx.measureText(designName.toUpperCase());

        if (designNameTextMeasurement.width < targetdesignNameWidth) {
          do {
            designNameFontSize += 1;
            mainCtx.font = "bold " + (designNameFontSize) + 'px "Arial"';
            designNameTextMeasurement = mainCtx.measureText(designName.toUpperCase());
          } while ((designNameTextMeasurement.width < targetdesignNameWidth) && (designNameFontSize < 90));
        }
        // else {
        //   do {
        //     designNameFontSize -= 1;
        //     mainCtx.font = "bold " + (designNameFontSize) + 'px "Arial"';
        //     designNameTextMeasurement = mainCtx.measureText(designName.toUpperCase());
        //   } while ((designNameTextMeasurement.width > targetdesignNameWidth) && (designNameFontSize > 5));
        // }

        (window as any)._design_name_font_size = designNameFontSize;

        mainCtx.save();
        mainCtx.translate(mainCanvas.width / 2, 90);
        if (!(window as any).APP_CONFIG.skipWatermark) {
          mainCtx.fillText(designName.toUpperCase(), 0, 0);
        }
        mainCtx.restore();

        if ((window as any).APP_CONFIG.renderTagline) {
          mainCtx.save();
          mainCtx.font = `normal ${32}px "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", "sans-serif"`;
          mainCtx.fillStyle = '#555';
          mainCtx.translate(0, 1700);
          mainCtx.textAlign = 'left';
          mainCtx.fillText(((window as any).APP_CONFIG ? (window as any).APP_CONFIG.renderTagline : "mensleaguesweaters.com").split("").join(' '), 0, 0);
          mainCtx.restore();

          mainCtx.save();
          mainCtx.translate(mainCanvas.width - 100, 1600);
          const mainLogoImage = layersImgCached[logo512];
          if (mainLogoImage && !(window as any).APP_CONFIG.skipWatermark) {
            mainCtx.drawImage(mainLogoImage, -((100 / mainLogoImage.height) * mainLogoImage.width)/2, 0, (100 / mainLogoImage.height) * mainLogoImage.width, 100);
          }
          mainCtx.restore();
        }
        else {
          mainCtx.save();
          mainCtx.translate(mainCanvas.width / 2, 1600);
          const mainLogoImage = layersImgCached[logo512];
          if (mainLogoImage && !(window as any).APP_CONFIG.skipBottomWatermark) {
            mainCtx.drawImage(mainLogoImage, -((100 / mainLogoImage.height) * mainLogoImage.width)/2, 0, (100 / mainLogoImage.height) * mainLogoImage.width, 100);
          }
          mainCtx.restore();
        }
      }
      else {
        
        // render final image
        mainCtx.rect(0, 0, mainCanvas.width, mainCanvas.height);
        mainCtx.fillStyle = 'white';
        mainCtx.fill();

        overlayShadowCroppedCtx.clearRect(0, 0, overlayShadowCroppedCanvas.width, overlayShadowCroppedCanvas.height);
        cropOverlayShadow(overlayShadowCanvas, overlayShadowCtx, overlayShadowCroppedCanvas, overlayShadowCroppedCtx, part1Canvas, part1Ctx);

        // mainCtx.drawImage(part3Canvas, -200, 214);
        // mainCtx.drawImage(part2Canvas, 500, 260);
        // mainCtx.drawImage(part1Canvas, 250, 134);
        mainCtx.drawImage(part1Canvas, 0, 0);

        let designNameFontSize = 140;

        mainCtx.fillStyle = '#000000';
        mainCtx.strokeStyle = '#000000';
        mainCtx.lineWidth = 0
        mainCtx.font = "bold " + (designNameFontSize) + 'px "Arial"';
        mainCtx.textAlign = 'center';

        let designName = design.design_name;
        if (!designName) {
          designName = "[Your Design]";
        }

        designName = `${designName}`;
        let targetdesignNameWidth = mainCanvas.width - 100;

        let designNameTextMeasurement = mainCtx.measureText(designName.toUpperCase());

        if (designNameTextMeasurement.width < targetdesignNameWidth) {
          do {
            designNameFontSize += 1;
            mainCtx.font = "bold " + (designNameFontSize) + 'px "Arial"';
            designNameTextMeasurement = mainCtx.measureText(designName.toUpperCase());
          } while ((designNameTextMeasurement.width < targetdesignNameWidth) && (designNameFontSize < 249));
        }
        else {
          do {
            designNameFontSize -= 1;
            mainCtx.font = "bold " + (designNameFontSize) + 'px "Arial"';
            designNameTextMeasurement = mainCtx.measureText(designName.toUpperCase());
          } while ((designNameTextMeasurement.width > targetdesignNameWidth) && (designNameFontSize > 20));
        }

        (window as any)._design_name_font_size = designNameFontSize;

        mainCtx.save();
        mainCtx.translate(mainCanvas.width / 2, 200);
        if (!(window as any).APP_CONFIG.skipWatermark) {
          mainCtx.fillText(designName.toUpperCase(), 0, 0);
        }
        mainCtx.restore();

        mainCtx.save();
        mainCtx.translate(mainCanvas.width / 2, 1300);
        const mainLogoImage = layersImgCached[logo512];
        if (mainLogoImage && !(window as any).APP_CONFIG.skipBottomWatermark) {
          mainCtx.drawImage(mainLogoImage, -((150 / mainLogoImage.height) * mainLogoImage.width)/2, 0, (150 / mainLogoImage.height) * mainLogoImage.width, 150);
        }
        mainCtx.restore();

        mainCtx.save();
        mainCtx.font = `normal ${32}px "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", "sans-serif"`;
        mainCtx.fillStyle = '#555';
        mainCtx.translate(mainCanvas.width / 2, 1468);
        mainCtx.fillText(((window as any).APP_CONFIG ? (window as any).APP_CONFIG.renderTagline : "mensleaguesweaters.com").split("").join(' '), 0, 0);
        mainCtx.restore();
      }

      const imageData = mainCanvas.toDataURL("image/jpeg")
      const overlayData = overlayShadowCroppedCanvas.toDataURL("image/png")
      resolve({render: imageData, overlayShadow: overlayData});
    }

    const loadImage = (imageUrl: string) => {
      if (!layersImgCached[imageUrl]) {
        layersImgCached[imageUrl] = new Image();
        layersImgCached[imageUrl].crossOrigin = "anonymous";
        layersImgCached[imageUrl].onload = startIfAllLoaded;
        layersImgCached[imageUrl].src = imageUrl;
        return true;
      }
      return false;
    };

    let loadingCounter = 0;
    if (design.logo_1 && loadImage(design.logo_1)) loadingCounter += 1;
    if (design.logo_2 && loadImage(design.logo_2)) loadingCounter += 1;
    if (design.logo_3 && loadImage(design.logo_3)) loadingCounter += 1;
    if (design.logo_4 && loadImage(design.logo_4)) loadingCounter += 1;
    if (design.logo_5 && loadImage(design.logo_5)) loadingCounter += 1;
    if (logo512 && loadImage(logo512)) loadingCounter += 1;

    if (design.template_data) {
      if (design.template_data.template && loadImage(design.template_data.template)) loadingCounter += 1;
      if (design.template_data.template_shading && loadImage(design.template_data.template_shading)) loadingCounter += 1;
      if (design.template_data.number_of_subparts > 0) {
        design.template_data.subpart_1
          .filter(subpart => subpart.id === design.subpart_1)
          .forEach(subpart => {
            if (subpart.template && loadImage(subpart.template)) loadingCounter += 1;
            if (subpart.template_shading && loadImage(subpart.template_shading)) loadingCounter += 1;
          });
      }
      if (design.template_data.number_of_subparts > 1) {
        design.template_data.subpart_2
          .filter(subpart => subpart.id === design.subpart_2)
          .forEach(subpart => {
            if (subpart.template && loadImage(subpart.template)) loadingCounter += 1;
            if (subpart.template_shading && loadImage(subpart.template_shading)) loadingCounter += 1;
          });
      }
      if (design.template_data.number_of_subparts > 2) {
        design.template_data.subpart_3
          .filter(subpart => subpart.id === design.subpart_3)
          .forEach(subpart => {
            if (subpart.template && loadImage(subpart.template)) loadingCounter += 1;
            if (subpart.template_shading && loadImage(subpart.template_shading)) loadingCounter += 1;
          });
      }
      if (design.template_data.number_of_subparts > 3) {
        design.template_data.subpart_4
          .filter(subpart => subpart.id === design.subpart_4)
          .forEach(subpart => {
            if (subpart.template && loadImage(subpart.template)) loadingCounter += 1;
            if (subpart.template_shading && loadImage(subpart.template_shading)) loadingCounter += 1;
          });
      }
      if (design.template_data.number_of_subparts > 4) {
        design.template_data.subpart_5
          .filter(subpart => subpart.id === design.subpart_5)
          .forEach(subpart => {
            if (subpart.template && loadImage(subpart.template)) loadingCounter += 1;
            if (subpart.template_shading && loadImage(subpart.template_shading)) loadingCounter += 1;
          });
      }
    }

    if (loadingCounter == 0) {
      setTimeout(function() {
        start();
      }, 0);
    }

  });
}