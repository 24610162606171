import React, {
  useState,
  useEffect
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Collapse,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Tooltip,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import ShareIcon from '@material-ui/icons/Share';
import InfoIcon from '@material-ui/icons/Info';
import CategoryIcon from '@material-ui/icons/Category';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { shadeColor } from '../utils/shadeColor';

import {
  useCustomizerData
} from '../data/CustomizerDataAPI';

import generateFontsCSS from '../utils/generateFontsCSS';

import InteractiveRenderer from '../components/InteractiveRenderer';
import CustomizerPanel from '../components/CustomizerPanel';
import SaveDialog from '../components/SaveDialog';
import ShareDialog from '../components/ShareDialog';
import InfoDialog from '../components/InfoDialog';
import GroupSelectorDialog from '../components/GroupSelectorDialog';

import mainLogo512 from '../img/logo512.png';
let logo512 = ((window as any).APP_CONFIG && (window as any).APP_CONFIG.siteLogo) ? (window as any).APP_CONFIG.siteLogo : mainLogo512;

const useStyles = makeStyles((theme) => ({
  root: {

  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
  },
  loadingLogo: {
    display: 'block',
    marginTop: 20,
    marginBottom: 20
  },
  loadingText: {
    width: 200,
    textAlign: 'center',
    opacity: '0.4',
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
  },
  errorText: {
    textAlign: 'center',
  },
  '@keyframes blinker': {
    from: {opacity: 1},
    to: {opacity: 0}
  },
  topBanner: {
    position: 'relative',
    backgroundColor: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
    padding: 10
  },
  topBannerText: {
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: 600,
  },
  topBannerCloseBtn: {
    position: 'absolute',
    top: 5,
    right: 10,
    color: '#ffffff',
  },
  headerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "solid 1px rgb(238,238,238)",
    backgroudColor: '#fff',
  },
  headerLogoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    padding: 10,
  },
  headerLogo: {
    
  },
  headerLogoText: {
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: "solid 1px #444",
    fontWeight: 600,
    color: '#444'
  },
  headerMenuContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerMenuButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    borderLeft: "solid 1px rgb(238,238,238)",
    height: 69,
    lineHeight: '69px',
  },
  headerMenuShareButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 69,
    lineHeight: '69px',
  },
  greyText: {
    color: '#adadad',
  },
  customizerPanel: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  rightPriceLabelContainer: {
    position: 'absolute',
    top: 250,
    right: 0,
    padding: '5px',
    paddingRight: '10px',
    background: 'rgba(0,0,0,.333)',
    color: '#fff',
  }
}));


function LoadingComponent() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <div className={classes.loadingContainer}>
        <img src={logo512} alt="" width="300" className={classes.loadingLogo} />
        <Typography className={classes.loadingText}>LOADING</Typography>
      </div>
    </div>
  );  
}


function LoadingErrorComponent() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <div className={classes.loadingContainer}>
        <img src={logo512} alt="" width="" height="300" className={classes.loadingLogo} />
        <Typography className={classes.errorText}>Something went wrong. Please try again.</Typography>
        <Button href="" startIcon={<ReplayIcon />}>Reload</Button>
      </div>
    </div>
  );  
}


interface TopBannerProps {
  siteSetting?: SiteSetting;
  visible: boolean;
  onHide: () => void;
}
function TopBanner({siteSetting, visible, onHide}: TopBannerProps) {
  const classes = useStyles();

  if (!siteSetting) return null;
  return (
    <Collapse
        in={visible && siteSetting && Boolean(siteSetting.top_banner_message_html)}
      >
        <div className={classes.topBanner}>
          <Hidden smDown>
            <div className={`MuiTypography-root MuiTypography-body1 ${classes.topBannerText}`}><div dangerouslySetInnerHTML={{'__html': siteSetting.top_banner_message_html}} /></div>
          </Hidden>
          <Hidden mdUp>
            <Typography className={classes.topBannerText} style={{marginRight: '30px', fontSize: '.7rem'}}><div dangerouslySetInnerHTML={{'__html': siteSetting.top_banner_message_html}} /></Typography>
          </Hidden>
          <IconButton aria-label="close" size="small" className={classes.topBannerCloseBtn} onClick={() => onHide()}>
            <CloseIcon />
          </IconButton>
        </div>
      </Collapse>
  );  
}

const SaveButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
    '&:hover': {
      backgroundColor: shadeColor((window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422', 80),
    },
  },
}))(Button);


interface TopHeaderProps {
  siteSetting?: SiteSetting;
  onSavePressed: () => void;
  onSharePressed: () => void;
  onResetPressed: () => void;
  onInfoPressed: () => void;
  onGroupSelectorPressed: () => void;
  onPricePressed: () => void;
}
function TopHeader({
  siteSetting,
  onSavePressed,
  onSharePressed,
  onGroupSelectorPressed,
  onResetPressed,
  onInfoPressed,
  onPricePressed
}: TopHeaderProps) {
  const classes = useStyles();

  if (!siteSetting) return null;
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerLogoContainer}>
        <ButtonBase>
          <img src={logo512} alt="" width="" height="48" className={classes.headerLogo} />
        </ButtonBase>
        {!siteSetting.hide_site_name_in_header && <ButtonBase>
          <Typography className={classes.headerLogoText}>{(window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteName : "Customizer"}</Typography>
        </ButtonBase>}
      </div>
      <div className={classes.headerMenuContainer}>
        <Hidden smDown>
          {!!siteSetting.price_content && <div className={classes.headerMenuShareButtonContainer}>
            <Tooltip title="Price">
              <IconButton
                aria-label="Price"
                size="small" 
                className={classes.greyText}
                onClick={onPricePressed}
              ><MonetizationOnIcon /></IconButton>
            </Tooltip>
          </div>}
          {!!siteSetting.info_content && <div className={classes.headerMenuButtonContainer}>
            <Tooltip title="How to order">
              <IconButton
                aria-label="How to order"
                size="small" 
                className={classes.greyText}
                onClick={onInfoPressed}
              ><InfoIcon /></IconButton>
            </Tooltip>
          </div>}
          {!!siteSetting.show_category_selector && <div className={classes.headerMenuButtonContainer}>
            <Tooltip title="Select Category">
              <IconButton
                aria-label="Select Category"
                size="small" 
                className={classes.greyText}
                onClick={onGroupSelectorPressed}
              ><CategoryIcon /></IconButton>
            </Tooltip>
          </div>}
          <div className={classes.headerMenuButtonContainer}>
            <Tooltip title="Start Over">
              <IconButton
                aria-label="Start Over"
                size="small" 
                className={classes.greyText}
                onClick={() => onResetPressed()}
              ><ReplayIcon /></IconButton>
            </Tooltip>
          </div>
          <div className={classes.headerMenuButtonContainer}>
            <SaveButton
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={() => {
                if (onSavePressed) onSavePressed();
              }}
            >SUBMIT</SaveButton>
          </div>  
        </Hidden>
        <Hidden mdUp>
          <div className={classes.headerMenuShareButtonContainer}>
            <Tooltip title="How to order">
              <IconButton
                aria-label="How to order"
                size="small" 
                className={classes.greyText}
                onClick={onInfoPressed}
              ><InfoIcon /></IconButton>
            </Tooltip>
          </div>
          <div className={classes.headerMenuButtonContainer}>
            <SaveButton
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              onClick={() => {
                if (onSavePressed) onSavePressed();
              }}
            >SUBMIT</SaveButton>
          </div>  
        </Hidden>
      </div>
    </div>
  );  
}


interface PriceLabelProps {
  siteSetting?: SiteSetting;
}
function PriceLabel({siteSetting}: PriceLabelProps) {
  const classes = useStyles();
  if (!siteSetting) return null;
  if (!siteSetting.right_pricing_label_html) return null;
  return (
    <div className={classes.rightPriceLabelContainer} dangerouslySetInnerHTML={{'__html': siteSetting.right_pricing_label_html}} />
  );
}


function HomePage() {
  const classes = useStyles();
  const [design, setDesign] = useState({
    design_name: '',
    notes: '',
    template: undefined,
    template_data: undefined,

    text_1: '',
    text_fill_color_1: '',
    text_stroke_color_1: '',
    text_font_id_1: undefined,
    text_font_name_1: undefined,
    text_position_1: {},
    text_font_letter_spacing_1: 0,

    text_2: '',
    text_fill_color_2: '',
    text_stroke_color_2: '',
    text_font_id_2: undefined,
    text_font_name_2: undefined,
    text_position_2: {},
    text_font_letter_spacing_2: 0,

    text_3: '',
    text_fill_color_3: '',
    text_stroke_color_3: '',
    text_font_id_3: undefined,
    text_font_name_3: undefined,
    text_position_3: {},
    text_font_letter_spacing_3: 0,

    text_4: '',
    text_fill_color_4: '',
    text_stroke_color_4: '',
    text_font_id_4: undefined,
    text_font_name_4: undefined,
    text_position_4: {},
    text_font_letter_spacing_4: 0,

    text_5: '',
    text_fill_color_5: '',
    text_stroke_color_5: '',
    text_font_id_5: undefined,
    text_font_name_5: undefined,
    text_position_5: {},
    text_font_letter_spacing_5: 0,

    text_6: '',
    text_fill_color_6: '',
    text_stroke_color_6: '',
    text_font_id_6: undefined,
    text_font_name_6: undefined,
    text_position_6: {},
    text_font_letter_spacing_6: 0,

    color_1: '#000000',
    color_2: '#000000',
    color_3: '#000000',
    color_4: '#000000',
    color_5: '#000000',

    logo_1: undefined,
    logo_1_file: undefined,
    logo_position_1: {},
    
    logo_2: undefined,
    logo_2_file: undefined,
    logo_position_2: {},
    
    logo_3: undefined,
    logo_3_file: undefined,
    logo_position_3: {},
    
    logo_4: undefined,
    logo_4_file: undefined,
    logo_position_4: {},
    
    logo_5: undefined,
    logo_5_file: undefined,
    logo_position_5: {},
    
    rendered_image_url: undefined,
    rendered_image_file: undefined,
    customer_name: '',
    customer_email: '',
    extra_recipients: []
  } as Design);
  const [showTopBanner, setShowTopBanner] = useState(true);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showGroupSelector, setShowGroupSelector] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showPriceDialog, setShowPriceDialog] = useState(false);
  const [customizer, customizerLoading, customizerError, saveCustomizerDesign, saveDesignLoading] = useCustomizerData();
  const [initialLoading, setInitialLoading] = useState(true);

  const [selectedPanel, setSelectedPanel] = useState('designName');
  const [selectedSubPanel, setSelectedSubPanel] = useState('');
  const [group, setGroup] = useState(window.location.pathname.replace(/\//g, ''));

  useEffect(() => {
    if (customizer && customizer.fonts) {
      generateFontsCSS(customizer.fonts);
    }

    let templates: Template[] = [];
    if (customizer && customizer.templates && customizer.templates.length) {
      templates = customizer.templates;
      if (group) {
        templates = customizer.templates.filter(t => {
          let included = false;
          t.groups.forEach(g => {
            if (g.slug === group) included = true;
          })
          return included;
        })
      }
    }

    if (initialLoading && !group && customizer?.site_setting.show_category_selector) {
      setShowGroupSelector(true);
      setInitialLoading(false);
    }

    if (templates.length) {
      setInitialLoading(false);
      let designClone = Object.assign({}, design);
      designClone.template_data = templates[0];
      designClone.template = templates[0].id;
      designClone.color_1 = templates[0].default_color_1;
      designClone.color_2 = templates[0].default_color_2;
      designClone.color_3 = templates[0].default_color_3;
      designClone.color_4 = templates[0].default_color_4;
      designClone.color_5 = templates[0].default_color_5;

      designClone.text_font_id_1 = templates[0].text_font_1_id;
      designClone.text_font_name_1 = templates[0].text_font_1_name;
      designClone.text_fill_color_1 = templates[0].text_fill_color_1;
      designClone.text_stroke_color_1 = templates[0].text_stroke_color_1;
      designClone.text_font_letter_spacing_1 = templates[0].text_font_letter_spacing_1;

      designClone.text_font_id_2 = templates[0].text_font_2_id;
      designClone.text_font_name_2 = templates[0].text_font_2_name;
      designClone.text_fill_color_2 = templates[0].text_fill_color_2;
      designClone.text_stroke_color_2 = templates[0].text_stroke_color_2;
      designClone.text_font_letter_spacing_2 = templates[0].text_font_letter_spacing_2;
      
      designClone.text_font_id_3 = templates[0].text_font_3_id;
      designClone.text_font_name_3 = templates[0].text_font_3_name;
      designClone.text_fill_color_3 = templates[0].text_fill_color_3;
      designClone.text_stroke_color_3 = templates[0].text_stroke_color_3;
      designClone.text_font_letter_spacing_3 = templates[0].text_font_letter_spacing_3;
      
      designClone.text_font_id_4 = templates[0].text_font_4_id;
      designClone.text_font_name_4 = templates[0].text_font_4_name;
      designClone.text_fill_color_4 = templates[0].text_fill_color_4;
      designClone.text_stroke_color_4 = templates[0].text_stroke_color_4;
      designClone.text_font_letter_spacing_4 = templates[0].text_font_letter_spacing_4;
      
      designClone.text_font_id_5 = templates[0].text_font_5_id;
      designClone.text_font_name_5 = templates[0].text_font_5_name;
      designClone.text_fill_color_5 = templates[0].text_fill_color_5;
      designClone.text_stroke_color_5 = templates[0].text_stroke_color_5;
      designClone.text_font_letter_spacing_5 = templates[0].text_font_letter_spacing_5;

      designClone.text_font_id_6 = templates[0].text_font_6_id;
      designClone.text_font_name_6 = templates[0].text_font_6_name;
      designClone.text_fill_color_6 = templates[0].text_fill_color_6;
      designClone.text_stroke_color_6 = templates[0].text_stroke_color_6;
      designClone.text_font_letter_spacing_6 = templates[0].text_font_letter_spacing_6;

      designClone.logo_1 = templates[0].logo_1;
      designClone.logo_2 = templates[0].logo_2;
      designClone.logo_3 = templates[0].logo_3;
      designClone.logo_4 = templates[0].logo_4;
      designClone.logo_5 = templates[0].logo_5;

      setDesign(designClone);
      setTimeout(() => {
        // re-render again in case fonts loaded late
        let designClone2 = Object.assign({}, designClone);
        setDesign(designClone2);
      }, 1000);
    }

  }, [customizer, group])
  
  useEffect(() => {
    (window as any)._customizer_is_editor_dirty = true;
  }, [design]);
  
  if (customizerLoading) return (<LoadingComponent />);

  if (!customizerLoading && customizerError) {
    return (<LoadingErrorComponent />);
  }

  const onResetPressed = () => {
    setShowResetDialog(!showResetDialog);
  }

  return (
    <div className={classes.root}>
      <TopBanner
        siteSetting={customizer?.site_setting}
        visible={showTopBanner}
        onHide={() => setShowTopBanner(false)}
      />
      <TopHeader
        siteSetting={customizer?.site_setting}
        onSavePressed={() => {
          console.log(design);
          setShowSaveDialog(true);
        }}
        onSharePressed={() => setShowShareDialog(true)}
        onInfoPressed={() => setShowInfoDialog(true)}
        onGroupSelectorPressed={() => setShowGroupSelector(true)}
        onPricePressed={() => setShowPriceDialog(true)}
        onResetPressed={onResetPressed}
      />
      {!showGroupSelector && <InteractiveRenderer
        siteSetting={customizer?.site_setting}
        topBannerShown={showTopBanner}
        design={design}
        onDesignChanged={(design: any) => {
          setDesign(design);
          console.log('design', design)
        }}
        onResetPressed={onResetPressed}
        selectedPanel={selectedPanel}
        selectedSubPanel={selectedSubPanel}
        onPanelChanged={(selectedPanel, selectedSubPanel) => {
          setSelectedPanel(selectedPanel);
          setSelectedSubPanel(selectedSubPanel);
        }}
      />}
      <PriceLabel
        siteSetting={customizer?.site_setting}
      />
      <CustomizerPanel
        customizer={customizer}
        design={design}
        onDesignChanged={(design: any) => setDesign(design)}
        onResetPressed={onResetPressed}
        className={classes.customizerPanel}
        selectedPanel={selectedPanel}
        selectedSubPanel={selectedSubPanel}
        onPanelChanged={(selectedPanel, selectedSubPanel) => {
          setSelectedPanel(selectedPanel);
          setSelectedSubPanel(selectedSubPanel);
        }}
      />

      <SaveDialog
        siteSetting={customizer?.site_setting}
        design={design}
        shown={showSaveDialog}
        onClose={() => setShowSaveDialog(false)}
        onSave={async (saveData: any) => {
          const [success, result, error] = await saveCustomizerDesign(saveData);
          
          if (success) {
            (window as any)._customizer_design_id = result.id;
            (window as any)._customizer_is_editor_dirty = false;
            setShowSaveDialog(false);
            if (!showShareDialog) {
              if (customizer && customizer.site_setting && customizer.site_setting.save_redirect) {
                const designId = (window as any)._customizer_is_editor_dirty ? undefined : (window as any)._customizer_design_id;
                const shareUrl = `${window.location.origin}/design/${designId}/`;
                const redirectUrl = `${customizer.site_setting.save_redirect}?share_url=${shareUrl}`;
                window.location.replace(redirectUrl);
              }
              else {
                setShowShareDialog(true);
              }
            }
          }
          else {
            console.log('error saving data:', error.status, error.message);
            if (error && error.status == 1200) {
              window.alert('Error saving your design. Please check your internet connection and try again.');
            }
            else if (error && error.status) {
              window.alert(`Error saving your design. Please try again. Contact us if the issue persist. [${error.status}]`);
            }
            else {
              window.alert(`Error saving your design. Please try again. Contact us if the issue persist. [${error.message}]`);
            }
          }
        }}
        isSaving={saveDesignLoading}
        emailListEnabled={customizer?.site_setting ? customizer.site_setting.mail_list_enabled : false}
      />

      <ShareDialog
        shown={showShareDialog}
        onClose={() => setShowShareDialog(false)}
        onSave={() => {
          setShowSaveDialog(true);
        }}
      />

      <InfoDialog
        content={customizer?.site_setting.info_content}
        shown={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
      />

      <InfoDialog
        content={customizer?.site_setting.price_content}
        shown={showPriceDialog}
        onClose={() => setShowPriceDialog(false)}
      />

      <GroupSelectorDialog
        shown={showGroupSelector}
        templates={(customizer && customizer.templates && customizer.templates.length) ? customizer.templates : []}
        onGroupSelected={(group) => {
          if (!group) setGroup('');
          else setGroup(group.slug);
          setShowGroupSelector(false);
        }}
      />

      <Dialog
        open={showResetDialog}
        onClose={() => setShowResetDialog(false)}
        aria-labelledby="confirm-reset-dialog-title"
        aria-describedby="confirm-reset-dialog-description"
      >
        <DialogTitle id="confirm-reset-dialog-title">Start Again From Scratch?</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-reset-dialog-description">
            Do you want to start over? All unsaved changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResetDialog(false)} color="primary">
            Cancel
          </Button>
          <SaveButton variant="contained" onClick={() => window.location.reload()} color="primary" startIcon={<ReplayIcon />} >
            Start Over
          </SaveButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HomePage;