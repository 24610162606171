
export default function letterSpaceAdjustment(text: string, point: number) {
  let newText = '';
  let spacing = '';
  for (let i = 0; i < point; i++) {
    spacing = `${spacing}${String.fromCharCode(8202)}`;
  }
  text.split('').forEach(c => {
    newText = `${newText}${c}${spacing}`;
  });
  return newText.trim();
}