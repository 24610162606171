

export default function generateFontsCSS(fonts: Font[]) {
  if (fonts.length == 0) return;

  let importantFonts = [{
    font: "/fonts/myriad-pro-black.otf",
    name: 'Myriad Pro Black',
  }]

  let css = '';

  importantFonts.forEach((item: any, i: number) => {
    css += `@font-face {font-family: '${item.name}'; src: url("${item.font}"); font-display: block;} `;
  });

  fonts.forEach((item: any, i: number) => {
    css += `@font-face {font-family: '${item.name}'; src: url("${item.font}"); font-display: block;} `;
  });

  let cssElement = document.getElementById('customizer_fonts_css');
  if (!cssElement) {
    cssElement = document.createElement('style');
    cssElement.setAttribute("id", 'customizer_fonts_css');
    document.head.appendChild(cssElement);
  }
  cssElement.innerText = css;

  let preloadContainerElement = document.getElementById('fonts_preload_container');
  importantFonts.forEach((item: any, i: number) => {
    let preloadElement = document.createElement('div');
    preloadElement.textContent = `font preload ${item.name}`;
    preloadElement.style.fontFamily = item.name;
    preloadContainerElement?.appendChild(preloadElement);
  });

  fonts.forEach((item, i) => {
    let preloadElement = document.createElement('div');
    preloadElement.textContent = `font preload ${item.name}`;
    preloadElement.style.fontFamily = `"${item.name}"`;
    preloadContainerElement?.appendChild(preloadElement);
  });

}
