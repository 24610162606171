import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Grid,
  Collapse,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import { shadeColor } from '../utils/shadeColor';

const useStyles = makeStyles((theme) => ({

  extraEmailsListContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: 10,
    marginBottom: 10,
    '& > div': {
      marginRight: 10,
    }
  },
  extraEmailAddButtonContainer: {
    marginTop: 10,
  }
}));

const SaveButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
    '&:hover': {
      backgroundColor: shadeColor((window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422', 80),
    },
  },
}))(Button);

export default ({shown, onClose, onSave}: any) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState(false);

  const designId = (window as any)._customizer_is_editor_dirty ? undefined : (window as any)._customizer_design_id;

  return (
    <Dialog onClose={onClose} aria-labelledby="save-dialog-title" open={shown} style={{zIndex: 999999}}>
      <DialogTitle id="save-dialog-title">Share Your Design</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {designId ? "You can use the following url to share your design:" : "You have unsaved changes. Please save your design first!"}
        </DialogContentText>
          {designId &&
            <TextField
              margin="dense"
              id="share-url"
              variant="outlined"
              fullWidth
              value={`${window.location.origin}/design/${designId}/`}
            />
          }
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
        {!designId &&
          <SaveButton
            color="primary"
            onClick={onSave}
          >
            Save
          </SaveButton>
        }
        {designId && navigator.clipboard &&
          <SaveButton
            color="primary"
            onClick={() => {
              const url = `${window.location.origin}/design/${designId}/`;

              navigator.clipboard.writeText(url).then(function() {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000);
              }, function(err) {
                
              });
            }}
            disabled={isCopied}
          >
            {isCopied ? 'Copied' : 'Copy'}
          </SaveButton>
        }
      </DialogActions>
    </Dialog>
  )
}
