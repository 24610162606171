import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Collapse,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  TextField,
  Tooltip,
  Grid,
  Hidden,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ReplayIcon from '@material-ui/icons/Replay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import Dropzone from 'react-dropzone';
import Cropper from "react-cropper";

import "cropperjs/dist/cropper.css";


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  panelSwitcherParentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  panelToggleContainer: {
    marginRight: 20,
    marginBottom: 20,
  },
  panelSwitcherContainer: {
    minWidth: 260,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginBottom: 20,
  },
  panelSwitcherButtonsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  panelSwitcherSelectedContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'left',
  },
  panelSwitcherSelectedIndexContainer: {
    fontSize: '.75rem',
  },
  panelSwitcherSelectedPanelNameContainer: {
    textTransform: 'uppercase',
  },
  panelSelectorContainer: {
    marginLeft: 20,
    marginBottom: 20,
    width: 260,
  },
  displayBlock: {
    display: 'block',
  },
  panelContainer: {
    zIndex: 10,
  },
  teamNameContainer: {
    height: 64,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamNameFormControl: {
    margin: 20,
    marginBottom: 35,
  },
  patternContainer: {
    "-webkitBackdropFilter": 'blur(8px)',
    backdropFilter: 'blur(8px)',
    maxHeight: 300,
    overflowY: 'auto',
  },
  singleColorsContainer: {
    backgroundColor: '#ffffff',
    maxHeight: 240,
    overflowY: 'auto',
  },
  colorsContainer: {
    backgroundColor: '#ffffff',
  },
  colorsContainerSelectorParent: {
    borderTop: "solid 1px rgb(238,238,238)",
  },
  colorsTabSelector: {
    marginBottom: 10,
  },
  logoContainer: {
    
  },
  uploadContainer: {
    padding: 10,
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  selectedLogoImageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 160,
  },
  textPanelContainer: {
  },
  panelPadding: {
    padding: 20,
  },
  textPanelFormControl: {
    margin: 10,
  },
  textMultiPanelFormControl: {
    margin: 10,
    width: 'calc(100% - 20px)',
  },
  panelLabel: {
    backgroundColor: '#ffffff',
  },
  panelItemSelectedDimImage: {
    opacity: 0.7
  },
  panelItemSelectedIcon: {
    position: 'absolute',
  }
}));


interface DesignNamePanelProps {
  customizer: CustomizerData;
  design: Design;
  onDesignChanged: (design: Design) => void;
}
function DesignNamePanel({customizer, design, onDesignChanged}: DesignNamePanelProps) {
  const classes = useStyles();
  const [value, setValue] = useState(design.design_name ? design.design_name : '');
  const teamNamePanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);
  
  useEffect(() => {
    if (teamNamePanelTimeoutHandle.current) {
      clearTimeout(teamNamePanelTimeoutHandle.current);
      teamNamePanelTimeoutHandle.current = null;
    }
    teamNamePanelTimeoutHandle.current = setTimeout(() => {
      if (design.design_name != value) {
        let designClone = Object.assign({}, design);
        designClone.design_name = value;
        onDesignChanged(designClone);
      }
    }, 1000);
  }, [value]);
  
  return (
    <div className={classes.teamNameContainer}>
      <FormControl fullWidth variant="outlined" className={classes.teamNameFormControl}>
        <TextField
          id="design-name"
          label={customizer.site_setting.design_name_title ? customizer.site_setting.design_name_title : "Design Name" }
          variant="outlined"
          autoFocus
          value={value}
          onChange={(e: any) => setValue(e.target.value.substring(0, 255))}
        />
      </FormControl>
    </div>
  );
}


interface PatternPanelProps {
  customizer: CustomizerData;
  design: Design;
  onDesignChanged: (design: Design) => void;
}
function PatternPanel({customizer, design, onDesignChanged}: PatternPanelProps) {
  const classes = useStyles();
  const [value, setValue] = useState(design.template);
  const [group, setGroup] = useState(window.location.pathname.replace(/\//g, ''));
  const patternPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);


  let templates: Template[] = [];
  if (customizer && customizer.templates && customizer.templates.length) {
    templates = customizer.templates;
    if (group) {
      templates = customizer.templates.filter(t => {
        let included = false;
        t.groups.forEach(g => {
          if (g.slug === group) included = true;
        })
        return included;
      })
    }
  }


  useEffect(() => {
    if (patternPanelTimeoutHandle.current) {
      clearTimeout(patternPanelTimeoutHandle.current);
      patternPanelTimeoutHandle.current = null;
    }

    patternPanelTimeoutHandle.current = setTimeout(() => {
      if (design.template != value) {
        let designClone = Object.assign({}, design);
        designClone.template = value;

        let templateData = undefined as Template|undefined;
        customizer.templates.forEach((template) => {
          if (template.id == value) templateData = template;
        });
        designClone.template_data = templateData;

        if (!templateData) return;

        if (templateData.force_default_color) {
          designClone.color_1 = templateData.default_color_1;
          designClone.color_2 = templateData.default_color_2;
          designClone.color_3 = templateData.default_color_3;
          designClone.color_4 = templateData.default_color_4;
          designClone.color_5 = templateData.default_color_5;

          designClone.text_font_id_1 = templateData.text_font_1_id;
          designClone.text_font_name_1 = templateData.text_font_1_name;
          designClone.text_fill_color_1 = templateData.text_fill_color_1;
          designClone.text_stroke_color_1 = templateData.text_stroke_color_1;
          designClone.text_font_letter_spacing_1 = templateData.text_font_letter_spacing_1;
          
          designClone.text_font_id_2 = templateData.text_font_2_id;
          designClone.text_font_name_2 = templateData.text_font_2_name;
          designClone.text_fill_color_2 = templateData.text_fill_color_2;
          designClone.text_stroke_color_2 = templateData.text_stroke_color_2;
          designClone.text_font_letter_spacing_2 = templateData.text_font_letter_spacing_2;
          
          designClone.text_font_id_3 = templateData.text_font_3_id;
          designClone.text_font_name_3 = templateData.text_font_3_name;
          designClone.text_fill_color_3 = templateData.text_fill_color_3;
          designClone.text_stroke_color_3 = templateData.text_stroke_color_3;
          designClone.text_font_letter_spacing_3 = templateData.text_font_letter_spacing_3;
          
          designClone.text_font_id_4 = templateData.text_font_4_id;
          designClone.text_font_name_4 = templateData.text_font_4_name;
          designClone.text_fill_color_4 = templateData.text_fill_color_4;
          designClone.text_stroke_color_4 = templateData.text_stroke_color_4;
          designClone.text_font_letter_spacing_4 = templateData.text_font_letter_spacing_4;
          
          designClone.text_font_id_5 = templateData.text_font_5_id;
          designClone.text_font_name_5 = templateData.text_font_5_name;
          designClone.text_fill_color_5 = templateData.text_fill_color_5;
          designClone.text_stroke_color_5 = templateData.text_stroke_color_5;
          designClone.text_font_letter_spacing_5 = templateData.text_font_letter_spacing_5;

          designClone.text_font_id_6 = templateData.text_font_6_id;
          designClone.text_font_name_6 = templateData.text_font_6_name;
          designClone.text_fill_color_6 = templateData.text_fill_color_6;
          designClone.text_stroke_color_6 = templateData.text_stroke_color_6;
          designClone.text_font_letter_spacing_6 = templateData.text_font_letter_spacing_6;

        }

        if (!design.logo_1_file) designClone.logo_1 = templateData.logo_1;
        if (!design.logo_2_file) designClone.logo_2 = templateData.logo_2;
        if (!design.logo_3_file) designClone.logo_3 = templateData.logo_3;
        if (!design.logo_4_file) designClone.logo_4 = templateData.logo_4;
        if (!design.logo_5_file) designClone.logo_5 = templateData.logo_5;

        onDesignChanged(designClone);
      }
    }, 0);
  }, [value]);
  
  if (!customizer) return null;
  return (
    <div className={classes.patternContainer}>
      {templates.map((template, i) => {
        return (
          <Tooltip key={`tooltip-pattern-${i}`} title={template.name}>
            <ButtonBase onClick={() => {
              setValue(template.id);
            }}>
              <img
                src={template.thumbnail}
                width="64"
                height="64"
                alt={template.name}
                className={(design.template == template.id) ? classes.panelItemSelectedDimImage : ''}
              />
              {(design.template == template.id) &&
                <CheckIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
              }
            </ButtonBase>
          </Tooltip>
        );
      })}
    </div>
  );
}



interface SubPartPanelProps {
  customizer: CustomizerData;
  subParts: Template[];
  selectedSubPartId?: string;
  design: Design;
  onSubPartSelected: (subPart?: Template) => void;
}
function SubPartPanel({customizer, subParts, selectedSubPartId, design, onSubPartSelected}: SubPartPanelProps) {
  const classes = useStyles();
  
  if (!customizer) return null;
  return (
    <div className={classes.patternContainer}>
      <Tooltip title="None">
        <ButtonBase onClick={() => {
          onSubPartSelected(undefined);
        }}>
          <div style={{width: 64, height: 64}}></div>
          {(!selectedSubPartId) &&
            <NotInterestedIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
          }
          {(selectedSubPartId) &&
            <NotInterestedIcon fontSize="large" className={classes.panelItemSelectedIcon} />
          }
        </ButtonBase>
      </Tooltip>
      {subParts.map((template, i) => {
        return (
          <Tooltip key={`tooltip-subpart-${i}`} title={template.name}>
            <ButtonBase onClick={() => {
              onSubPartSelected(template);
            }}>
              <img
                src={template.thumbnail}
                width="64"
                height="64"
                alt={template.name}
                className={(selectedSubPartId == template.id) ? classes.panelItemSelectedDimImage : ''}
              />
              {(selectedSubPartId == template.id) &&
                <CheckIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
              }
            </ButtonBase>
          </Tooltip>
        );
      })}
    </div>
  );
}




interface SingleColorsProps {
  customizer: CustomizerData;
  color: string;
  onColorChange: (color: string) => void;
}
function SingleColorsPanel({customizer, color, onColorChange}: SingleColorsProps) {
  const classes = useStyles();
  const [value, setValue] = useState(color);

  if (!customizer) return null;
  return (
    <div className={classes.singleColorsContainer}>
      {customizer.colors.map((item, i) => {
        return (
          <Tooltip title={item.name} key={`color-${i}`}>
            <ButtonBase
              onClick={() => onColorChange(item.color)}
            >
              <div style={{
                width: '64px',
                height: '64px',
                backgroundColor: item.color,
              }}></div>
              {(item.color == color) &&
                <CheckIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
              }
            </ButtonBase>
          </Tooltip>
        );
      })}
    </div>
  );
}



interface ColorsPanelProps {
  customizer: CustomizerData;
  design: Design;
  onDesignChanged: (design: Design) => void;
  selectedPanel: string;
  onPanelChanged: (panel: string) => void;
}

function ColorsPanel({
  customizer,
  design,
  onDesignChanged,
  selectedPanel,
  onPanelChanged
}: ColorsPanelProps) {
  const classes = useStyles();
  // const [field, setField] = useState('color_1');
  const [color1, setColor1] = useState(design.color_1);
  const [color2, setColor2] = useState(design.color_2);
  const [color3, setColor3] = useState(design.color_3);
  const [color4, setColor4] = useState(design.color_4);
  const [color5, setColor5] = useState(design.color_5);
  const numberOfColor = design.template_data ? design.template_data.number_of_colors : 5;
  const colorsPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  let field = selectedPanel;
  if (['color_1', 'color_2', 'color_3', 'color_4', 'color_5'].indexOf(field) == -1) field = 'color_1';

  useEffect(() => {
    if (colorsPanelTimeoutHandle.current) {
      clearTimeout(colorsPanelTimeoutHandle.current);
      colorsPanelTimeoutHandle.current = null;
    }

    colorsPanelTimeoutHandle.current = setTimeout(() => {
      let isDirty = false;
      if (design.color_1 != color1) isDirty = true;
      if (design.color_2 != color2) isDirty = true;
      if (design.color_3 != color3) isDirty = true;
      if (design.color_4 != color4) isDirty = true;
      if (design.color_5 != color5) isDirty = true;

      let designClone = Object.assign({}, design);
      designClone.color_1 = color1;
      designClone.color_2 = color2;
      designClone.color_3 = color3;
      designClone.color_4 = color4;
      designClone.color_5 = color5;
      if (isDirty) onDesignChanged(designClone);
    }, 0);
  }, [color1, color2, color3, color4, color5]);

  useEffect(() => {
    if (design.color_1 != color1) setColor1(design.color_1);
    if (design.color_2 != color2) setColor2(design.color_2);
    if (design.color_3 != color3) setColor3(design.color_3);
    if (design.color_4 != color4) setColor4(design.color_4);
    if (design.color_5 != color5) setColor5(design.color_5);
  }, [design]);
  
  if (!customizer) return null;
  return (
    <div className={classes.colorsContainer}>
      <Paper className={classes.colorsTabSelector} square>
        <Tabs
          value={field}
          onChange={(event: any, nextField: string) => {
            onPanelChanged(nextField);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {(numberOfColor >=1) && <Tab label="Color 1" value="color_1" />}
          {(numberOfColor >=2) && <Tab label="Color 2" value="color_2" />}
          {(numberOfColor >=3) && <Tab label="Color 3" value="color_3" />}
          {(numberOfColor >=4) && <Tab label="Color 4" value="color_4" />}
          {(numberOfColor >=5) && <Tab label="Color 5" value="color_5" />}
        </Tabs>
      </Paper>
      <div className={classes.colorsContainerSelectorParent}>
        <Collapse
          in={field == 'color_1'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color1}
            onColorChange={(color: string) => setColor1(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_2'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color2}
            onColorChange={(color: string) => setColor2(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_3'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color3}
            onColorChange={(color: string) => setColor3(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_4'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color4}
            onColorChange={(color: string) => setColor4(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_5'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color5}
            onColorChange={(color: string) => setColor5(color)}
          />
        </Collapse>
      </div>
    </div>
  );
}


interface PositionSliderProps {
  value: number;
  setValue: (event: any, value: number|number[]) => void;
  step: number;
  min: number;
  max: number;
}
function PositionSlider({
  value,
  setValue,
  step,
  min,
  max,
}: PositionSliderProps) {
  const [minimum, setMinimum] = useState(min);
  const [maximum, setMaximum] = useState(max);
  return (
    <Grid container spacing={2} style={{marginTop: 0}}>
      <Grid item style={{display: 'flex', alignItems: 'center'}}>
        <RemoveIcon />
      </Grid>
      <Grid item xs>
        <Slider
          value={value}
          getAriaValueText={(value: number) => {
            return `${value}`;
          }}
          onChange={(event: any, value: number | number[]) => {
            setValue(event, value);
          }}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={step}
          marks
          min={minimum}
          max={maximum}          
        />
      </Grid>
      <Grid item style={{display: 'flex', alignItems: 'center'}}>
        <AddIcon />
      </Grid>
    </Grid>
  );
}


interface CustomImageEditorProps {
  show: boolean;
  preview?: string;
  file?: any;
  onClose?: () => void;
  onChanged: (logo: string|undefined, file: any|undefined) => void;
}

function CustomImageEditor(props: CustomImageEditorProps) {
  const [cropped, setCropped] = useState(props.preview);
  const [editingOption, setEditingOption] = useState(undefined as any|undefined);
  const [original, setOriginal] = useState(props.preview);
  const cropperRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!props.file) return;

    const reader = new FileReader()
    reader.onload = () => {
      const base64Url = reader.result;
      if (base64Url) {
        setOriginal(base64Url as string);
      }
    };

    reader.readAsDataURL(props.file)
    setEditingOption(undefined);
  }, [props.file])

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    let changed = cropper.getCroppedCanvas().toDataURL();
    setEditingOption(cropper.getCropBoxData());
    setCropped(changed);
  };

  const onSave = () => {
    if (cropped) props.onChanged(cropped, props.file);
    if (props.onClose) props.onClose();
  }

  const onClose = () => {
    if (props.onClose) props.onClose()
  }

  if (!props.file) return (<></>);

  return (
    <Dialog onClose={onClose} aria-labelledby="edit-image" open={!!props.show} style={{zIndex: 999999}}>
      <DialogContent>
      <Cropper
        src={original}
        style={{ height: 400, width: "100%" }}
        // Cropper.js options
        initialAspectRatio={16 / 9}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
      />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}


interface SingleLogoPanelProps {
  customizer: CustomizerData;
  design: Design;
  label: string;
  logo?: string;
  file?: any;
  position: any;
  onLogoChanged: (logo: string|undefined, position: any, file?: any) => void;
}
function SingleLogoPanel({customizer, design, label, logo, file, position, onLogoChanged}: SingleLogoPanelProps) {
  const classes = useStyles();
  const [showUploader, setShowUploader] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [editingOption, setEditingOption] = useState(undefined as any|undefined)

  const removeLogo = () => {
    onLogoChanged('', position, undefined);
    setShowUploader(true);
    setShowEditor(false);
  };

  if (!customizer) return null;

  const posX = position?.x ? position.x : 0;
  const posY = position?.y ? position.y : 0;
  const posW = position?.w ? position.w : 0;
  const posR = position?.r ? position.r : 0;

  const uploaderShown = showUploader || !logo;

  return (
    <div className={classes.logoContainer}>
      <CustomImageEditor
        show={showEditor}
        onClose={() => setShowEditor(false)}
        preview={logo}
        file={file}
        onChanged={(logo, file) => {
          let pos = Object.assign({}, position);
          onLogoChanged(logo, pos, file);
        }}
      />
      <Collapse
        in={!uploaderShown}
      >
        <div className={classes.selectedLogoImageContainer} style={{padding: 5}}>
          <img
            src={logo}
            alt={file?.name ? file.name : ''}
            style={{maxWidth: 310, maxHeight: 100, cursor: 'pointer'}}
            onClick={() => setShowUploader(true)}
          />
          <div style={{

          }}>
            <Button
              startIcon={<CloudUploadIcon />}
              onClick={() => setShowUploader(true)}
            ></Button>
            <Button
              startIcon={<EditIcon />}
              onClick={() => setShowEditor(true)}
            ></Button>
            <Button
              startIcon={<CloseIcon />}
              onClick={removeLogo}
            ></Button>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name="removeWhite"
                  color="primary"
                  checked={position.removeWhite}
                  onChange={(e: any) => {
                    const removeWhite = e.target.checked;
                    let pos = Object.assign({}, position);
                    pos.removeWhite = removeWhite;
                    onLogoChanged(logo, pos, file);
                  }}
                />
              }
              label="Remove White"
            />
          </div>
        </div>
      </Collapse>
      <Collapse
        in={uploaderShown}
      >
      <Grid container spacing={3}>
        {(file?.name && showUploader) &&
        <Grid item xs={12} sm={file?.name ? 3 : 12}>
            <div className={classes.selectedLogoImageContainer}>
              <img src={logo} height="100" alt={file?.name ? file.name : ''} />
              <Button
                startIcon={<EditIcon />}
                onClick={() => setShowEditor(true)}
              >Edit</Button>
              <Button
                startIcon={<CloseIcon />}
                onClick={() => setShowUploader(false)}
              >Cancel</Button>
            </div>
        </Grid>
        }
        <Grid item xs={12} sm={file?.name ? 9 : 12}>
          <Dropzone
            accept=".jpeg,.jpg,.png"
            onDrop={(acceptedFiles: any[]) => {
              if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];

                const reader = new FileReader()
                reader.onload = () => {
                  const base64Url = reader.result;
                  if (base64Url) {
                    onLogoChanged(base64Url as string, position, file);
                    setShowUploader(false);
                  }
                };

                reader.readAsDataURL(file)
              }
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section className={classes.uploadContainer}>
                <div className={classes.dropzone} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CloudUploadIcon fontSize="large" />
                  <Typography className={classes.textAlignCenter}>Click to choose a file or drag here</Typography>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>
      </Grid>
      </Collapse>
    </div>
  );
}


interface LogoPanelProps {
  customizer: CustomizerData;
  design: Design;
  onDesignChanged: (design: Design) => void;
  selectedPanel: string;
  onPanelChanged: (panel: string) => void;
}
function LogoPanel({customizer, design, onDesignChanged, selectedPanel, onPanelChanged}: LogoPanelProps) {
  const classes = useStyles();
  // const [field, setField] = useState('logo-1');

  let field = selectedPanel;
  if (['logo-1', 'logo-2', 'logo-3', 'logo-4', 'logo-5'].indexOf(field) == -1) field = 'logo-1';

  if (!customizer) return null;

  let numberOfLogos = design.template_data?.number_of_logos ? design.template_data.number_of_logos : 0;
  
  [1,2,3,4,5].forEach((i) => {
    if (i <= numberOfLogos) return;
    if ((design as any)[`logo_${i}`]) {
      numberOfLogos += 1;
    }
  });
  
  let nextLogo = '';
  if (numberOfLogos < 5) {
    nextLogo = `logo-${numberOfLogos+1}`;
  }

  return (
    <div className={classes.colorsContainer}>
      <Paper className={classes.colorsTabSelector} square>
        <Tabs
          value={field}
          onChange={(event: any, nextField: string) => {
            onPanelChanged(nextField);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {(numberOfLogos > 0) &&
          <Tab label={design.template_data?.logo_title_1 ? design.template_data.logo_title_1 : "Logo 1"} value="logo-1" />}
          {(numberOfLogos > 1) &&
          <Tab label={design.template_data?.logo_title_2 ? design.template_data.logo_title_2 : "Logo 2"} value="logo-2" />}
          {(numberOfLogos > 2) &&
          <Tab label={design.template_data?.logo_title_3 ? design.template_data.logo_title_3 : "Logo 3"} value="logo-3" />}
          {(numberOfLogos > 3) &&
          <Tab label={design.template_data?.logo_title_4 ? design.template_data.logo_title_4 : "Logo 4"} value="logo-4" />}
          {(numberOfLogos > 4) &&
          <Tab label={design.template_data?.logo_title_5 ? design.template_data.logo_title_5 : "Logo 5"} value="logo-5" />}
          {!!nextLogo &&
          <Tab icon={<AddIcon />} value={nextLogo} style={{minWidth: 50}} />}
        </Tabs>
      </Paper>
      <div className={classes.colorsContainerSelectorParent}>
        <Collapse
          in={field == 'logo-1'}
        >
          <SingleLogoPanel
            key={'logo-1'}
            label={design.template_data?.logo_title_1 ? design.template_data.logo_title_1 : "logo 1"}
            customizer={customizer}
            design={design}
            logo={design.logo_1}
            file={design.logo_1_file}
            position={design.logo_position_1}
            onLogoChanged={(logo, position, file) => {
              let designClone = Object.assign({}, design);
              designClone.logo_1 = logo;
              designClone.logo_1_file = file;
              designClone.logo_position_1 = position;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse
          in={field == 'logo-2'}
        >
          <SingleLogoPanel
            key={'logo-2'}
            label={design.template_data?.logo_title_2 ? design.template_data.logo_title_2 : "logo 2"}
            customizer={customizer}
            design={design}
            logo={design.logo_2}
            file={design.logo_2_file}
            position={design.logo_position_2}
            onLogoChanged={(logo, position, file) => {
              let designClone = Object.assign({}, design);
              designClone.logo_2 = logo;
              designClone.logo_2_file = file;
              designClone.logo_position_2 = position;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse
          in={field == 'logo-3'}
        >
          <SingleLogoPanel
            key={'logo-3'}
            label={design.template_data?.logo_title_3 ? design.template_data.logo_title_3 : "logo 5"}
            customizer={customizer}
            design={design}
            logo={design.logo_3}
            file={design.logo_3_file}
            position={design.logo_position_3}
            onLogoChanged={(logo, position, file) => {
              let designClone = Object.assign({}, design);
              designClone.logo_3 = logo;
              designClone.logo_3_file = file;
              designClone.logo_position_3 = position;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse
          in={field == 'logo-4'}
        >
          <SingleLogoPanel
            key={'logo-4'}
            label={design.template_data?.logo_title_4 ? design.template_data.logo_title_4 : "logo 4"}
            customizer={customizer}
            design={design}
            logo={design.logo_4}
            file={design.logo_4_file}
            position={design.logo_position_4}
            onLogoChanged={(logo, position, file) => {
              let designClone = Object.assign({}, design);
              designClone.logo_4 = logo;
              designClone.logo_4_file = file;
              designClone.logo_position_4 = position;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse
          in={field == 'logo-5'}
        >
          <SingleLogoPanel
            key={'logo-5'}
            label={design.template_data?.logo_title_5 ? design.template_data.logo_title_5 : "logo 5"}
            customizer={customizer}
            design={design}
            logo={design.logo_5}
            file={design.logo_5_file}
            position={design.logo_position_5}
            onLogoChanged={(logo, position, file) => {
              let designClone = Object.assign({}, design);
              designClone.logo_5 = logo;
              designClone.logo_5_file = file;
              designClone.logo_position_5 = position;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
      </div>
    </div>
  );
}



interface SingleTextPanelProps {
  customizer: CustomizerData;
  label: string;
  text: string;
  fill: string;
  stroke: string;
  effect?: string;
  effectOpt?: any;
  font?: string;
  onTextChanged: (text: string, fill: string, stroke: string, font?: string, effect?: string, effectOpt?: any) => void;
}
function SingleTextPanel({customizer, label, text, fill, stroke, font, effect, effectOpt, onTextChanged}: SingleTextPanelProps) {
  const classes = useStyles();

  if (!customizer) return null;

  let effectVal = effect ? effect : 'normal';
  return (
    <Grid container style={{maxHeight: 500, overflowY: 'auto'}}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant="outlined" className={classes.textMultiPanelFormControl}>
          <TextField
            label={label}
            variant="outlined"
            autoFocus
            value={text}
            onChange={(e: any) => {
              onTextChanged(
                e.target.value.substring(0, 255),
                fill,
                stroke,
                font
              );
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" className={classes.textMultiPanelFormControl}>
          <InputLabel>Font</InputLabel>
          <Select
            value={font}
            onChange={(e) => {
              const fontId = e.target.value as string;

              customizer.fonts.forEach((font: {id: string, name: string}) => {
                if (font.id == fontId) {
                  onTextChanged(
                    text,
                    fill,
                    stroke,
                    fontId
                  );
                }
              });
            }}
            label="Font"
          >
            {customizer.fonts.map((font: {id: string, name: string}) => <MenuItem key={`font-${font.id}`} value={font.id}>{font.name}</MenuItem>)}
          </Select>
          {/* <RadioGroup
            aria-label="Shape"
            name="text_shape"
            row
            value={effectVal}
            onChange={(event) => {
              const effect = event.target.value;
              const newEffectOpt = Object.assign({}, effectOpt)
              if (effect === 'arc') {
                if (newEffectOpt.arcIntensity === undefined) newEffectOpt.arcIntensity = 50;
              }
              onTextChanged(
                text,
                fill,
                stroke,
                font,
                effect,
                newEffectOpt
              );
            }}
          >
            <FormControlLabel value="normal" control={<Radio />} label="Normal" />
            <FormControlLabel value="arc" control={<Radio />} label="Arc" />
            <FormControlLabel value="vertical" control={<Radio />} label="Vertical" />
          </RadioGroup>
          <Collapse in={effect === 'arc'}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="reverseArc"
                    color="primary"
                    checked={effectOpt?.reverseArc}
                    onChange={(e: any) => {
                      const checked = e.target.checked;
                      const newEffectOpt = Object.assign({}, effectOpt, {reverseArc: checked})
                      onTextChanged(
                        text,
                        fill,
                        stroke,
                        font,
                        effect,
                        newEffectOpt
                      );
                    }}
                  />
                }
                label="Reverse Arc"
              />
              <Slider
                value={effectOpt?.arcIntensity ? effectOpt.arcIntensity : 0}
                getAriaValueText={(value: number) => {
                  return `${value}%`;
                }}
                onChange={(event: any, value: number | number[]) => {
                  const newEffectOpt = Object.assign({}, effectOpt, {arcIntensity: value as number})
                  onTextChanged(
                    text,
                    fill,
                    stroke,
                    font,
                    effect,
                    newEffectOpt
                  );
                }}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={5}
                marks
                min={20}
                max={50}
              />
            </div>
          </Collapse> */}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputLabel>Fill Color</InputLabel>
        <SingleColorsPanel
          customizer={customizer}
          color={fill}
          onColorChange={(color: string) => {
            onTextChanged(
              text,
              color,
              stroke,
              font
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputLabel>Stroke Color</InputLabel>
        <SingleColorsPanel
          customizer={customizer}
          color={stroke}
          onColorChange={(color: string) => {
            onTextChanged(
              text,
              fill,
              color,
              font
            );
          }}
        />
      </Grid>
    </Grid>
  );
}



interface TextPanelProps {
  customizer: CustomizerData;
  design: Design;
  onDesignChanged: (design: Design) => void;
  selectedPanel: string;
  onPanelChanged: (panel: string) => void;
}
function TextPanel({customizer, design, onDesignChanged, selectedPanel, onPanelChanged}: TextPanelProps) {
  const classes = useStyles();
  // const [field, setField] = useState('text-1');

  let field = selectedPanel;
  if (['text_1', 'text_2', 'text_3', 'text_4', 'text_5', 'text_6'].indexOf(field) == -1) field = 'text_1';

  if (!customizer) return null;

  let numberOfTexts = design.template_data?.number_of_texts ? design.template_data.number_of_texts : 0;
  
  [1,2,3,4,5,6].forEach((i) => {
    if (i <= numberOfTexts) return;
    if ((design as any)[`text_${i}`]) {
      numberOfTexts += 1;
    }
  });
  
  let nextText = '';
  if (numberOfTexts < 6) {
    nextText = `text_${numberOfTexts+1}`;
  }

  return (
    <div className={classes.textPanelContainer}>
      <Paper className={classes.colorsTabSelector} square>
        <Tabs
          value={field}
          onChange={(event: any, nextField: string) => {
            onPanelChanged(nextField);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {(numberOfTexts > 0) &&
          <Tab label={design.template_data?.text_title_1 ? design.template_data.text_title_1 : "Text 1"} value="text_1" />}
          {(numberOfTexts > 1) &&
          <Tab label={design.template_data?.text_title_2 ? design.template_data.text_title_2 : "Text 2"} value="text_2" />}
          {(numberOfTexts > 2) &&
          <Tab label={design.template_data?.text_title_3 ? design.template_data.text_title_3 : "Text 3"} value="text_3" />}
          {(numberOfTexts > 3) &&
          <Tab label={design.template_data?.text_title_4 ? design.template_data.text_title_4 : "Text 4"} value="text_4" />}
          {(numberOfTexts > 4) &&
          <Tab label={design.template_data?.text_title_5 ? design.template_data.text_title_5 : "Text 5"} value="text_5" />}
          {(numberOfTexts > 5) &&
          <Tab label={design.template_data?.text_title_6 ? design.template_data.text_title_6 : "Text 6"} value="text_6" />}
          {!!nextText &&
          <Tab icon={<AddIcon />} value={nextText} style={{minWidth: 50}} />}
        </Tabs>
      </Paper>
      <div>
        <Collapse
          in={field == 'text_1'}
        >
          <SingleTextPanel
            customizer={customizer}
            label="Text 1"
            text={design.text_1}
            fill={design.text_fill_color_1}
            stroke={design.text_stroke_color_1}
            font={design.text_font_id_1}
            effect={design.text_position_1.effect}
            effectOpt={design.text_position_1.effectOpt}
            onTextChanged={(text, fill, stroke, font, effect, effectOpt) => {
              let designClone = Object.assign({}, design);
              designClone.text_1 = text;
              designClone.text_fill_color_1 = fill;
              designClone.text_stroke_color_1 = stroke;
              designClone.text_font_id_1 = font;

              let fontName = undefined as string|undefined;
              let letterSpacing = 0;
              customizer.fonts.forEach((f) => {
                if (f.id === font) {
                  fontName = f.name;
                  letterSpacing = f.letter_spacing;
                }
              });

              designClone.text_font_name_1 = fontName;
              designClone.text_font_letter_spacing_1 = letterSpacing;
              if (effect) {
                designClone.text_position_1.effect = effect;
              }
              if (effectOpt) {
                designClone.text_position_1.effectOpt = effectOpt;
              }
              onDesignChanged(designClone);
            }}
          />
        </Collapse>

        <Collapse
          in={field == 'text_2'}
        >
          <SingleTextPanel
            customizer={customizer}
            label="Text 2"
            text={design.text_2}
            fill={design.text_fill_color_2}
            stroke={design.text_stroke_color_2}
            font={design.text_font_id_2}
            onTextChanged={(text, fill, stroke, font) => {
              let designClone = Object.assign({}, design);
              designClone.text_2 = text;
              designClone.text_fill_color_2 = fill;
              designClone.text_stroke_color_2 = stroke;
              designClone.text_font_id_2 = font;

              let fontName = undefined as string|undefined;
              let letterSpacing = 0;
              customizer.fonts.forEach((f) => {
                if (f.id === font) {
                  fontName = f.name;
                  letterSpacing = f.letter_spacing;
                }
              });

              designClone.text_font_name_2 = fontName;
              designClone.text_font_letter_spacing_2 = letterSpacing;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>

        <Collapse
          in={field == 'text_3'}
        >
          <SingleTextPanel
            customizer={customizer}
            label="Text 3"
            text={design.text_3}
            fill={design.text_fill_color_3}
            stroke={design.text_stroke_color_3}
            font={design.text_font_id_3}
            onTextChanged={(text, fill, stroke, font) => {
              let designClone = Object.assign({}, design);
              designClone.text_3 = text;
              designClone.text_fill_color_3 = fill;
              designClone.text_stroke_color_3 = stroke;
              designClone.text_font_id_3 = font;

              let fontName = undefined as string|undefined;
              let letterSpacing = 0;
              customizer.fonts.forEach((f) => {
                if (f.id === font) {
                  fontName = f.name;
                  letterSpacing = f.letter_spacing;
                }
              });

              designClone.text_font_name_3 = fontName;
              designClone.text_font_letter_spacing_3 = letterSpacing;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>

        <Collapse
          in={field == 'text_4'}
        >
          <SingleTextPanel
            customizer={customizer}
            label="Text 4"
            text={design.text_4}
            fill={design.text_fill_color_4}
            stroke={design.text_stroke_color_4}
            font={design.text_font_id_4}
            onTextChanged={(text, fill, stroke, font) => {
              let designClone = Object.assign({}, design);
              designClone.text_4 = text;
              designClone.text_fill_color_4 = fill;
              designClone.text_stroke_color_4 = stroke;
              designClone.text_font_id_4 = font;

              let fontName = undefined as string|undefined;
              let letterSpacing = 0;
              customizer.fonts.forEach((f) => {
                if (f.id === font) {
                  fontName = f.name;
                  letterSpacing = f.letter_spacing;
                }
              });

              designClone.text_font_name_4 = fontName;
              designClone.text_font_letter_spacing_4 = letterSpacing;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>

        <Collapse
          in={field == 'text_5'}
        >
          <SingleTextPanel
            customizer={customizer}
            label="Text 5"
            text={design.text_5}
            fill={design.text_fill_color_5}
            stroke={design.text_stroke_color_5}
            font={design.text_font_id_5}
            onTextChanged={(text, fill, stroke, font) => {
              let designClone = Object.assign({}, design);
              designClone.text_5 = text;
              designClone.text_fill_color_5 = fill;
              designClone.text_stroke_color_5 = stroke;
              designClone.text_font_id_5 = font;

              let fontName = undefined as string|undefined;
              let letterSpacing = 0;
              customizer.fonts.forEach((f) => {
                if (f.id === font) {
                  fontName = f.name;
                  letterSpacing = f.letter_spacing;
                }
              });

              designClone.text_font_name_5 = fontName;
              designClone.text_font_letter_spacing_5 = letterSpacing;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>

        <Collapse
          in={field == 'text_6'}
        >
          <SingleTextPanel
            customizer={customizer}
            label="Text 6"
            text={design.text_6}
            fill={design.text_fill_color_6}
            stroke={design.text_stroke_color_6}
            font={design.text_font_id_6}
            onTextChanged={(text, fill, stroke, font) => {
              let designClone = Object.assign({}, design);
              designClone.text_6 = text;
              designClone.text_fill_color_6 = fill;
              designClone.text_stroke_color_6 = stroke;
              designClone.text_font_id_6 = font;

              let fontName = undefined as string|undefined;
              let letterSpacing = 0;
              customizer.fonts.forEach((f) => {
                if (f.id === font) {
                  fontName = f.name;
                  letterSpacing = f.letter_spacing;
                }
              });

              designClone.text_font_name_6 = fontName;
              designClone.text_font_letter_spacing_6 = letterSpacing;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
      </div>
    </div>
  );
}


const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

interface CustomizerPanelProps {
  customizer?: CustomizerData;
  design: Design;
  onDesignChanged: (design: Design) => void;
  onResetPressed: () => void;
  selectedPanel: string;
  selectedSubPanel: string;
  onPanelChanged: (panel: string, subpanel: string) => void;
  className: string;
}
function CustomizerPanel({
  customizer,
  design,
  onDesignChanged,
  onResetPressed,
  className,
  selectedPanel,
  selectedSubPanel,
  onPanelChanged,
}: CustomizerPanelProps) {
  const classes = useStyles();
  const panelOptions: any = {
    designName: customizer?.site_setting?.design_name_title ? customizer.site_setting.design_name_title : "Design Name",
    pattern: customizer?.site_setting?.template_name_title ? customizer.site_setting.template_name_title : "Template",
    colors: "Colors",
    text: "Text",
    logo: "Logo"
  };

  const subPanelOptions: any = {
    designName: "",
    colors: "color_1",
    pattern: "",
    text: "text_1",
    logo: "logo-1"
  };

  if (design.template_data && design.template_data.number_of_subparts > 0) {
    panelOptions['subpart_1'] = design.template_data.subpart_title_1 ? design.template_data.subpart_title_1 : 'Extra 1';
  }
  if (design.template_data && design.template_data.number_of_subparts > 1) {
    panelOptions['subpart_2'] = design.template_data.subpart_title_2 ? design.template_data.subpart_title_2 : 'Extra 1';
  }
  if (design.template_data && design.template_data.number_of_subparts > 2) {
    panelOptions['subpart_3'] = design.template_data.subpart_title_3 ? design.template_data.subpart_title_3 : 'Extra 1';
  }
  if (design.template_data && design.template_data.number_of_subparts > 3) {
    panelOptions['subpart_4'] = design.template_data.subpart_title_4 ? design.template_data.subpart_title_4 : 'Extra 1';
  }
  if (design.template_data && design.template_data.number_of_subparts > 4) {
    panelOptions['subpart_5'] = design.template_data.subpart_title_5 ? design.template_data.subpart_title_5 : 'Extra 1';
  }

  const panelOptionKeys = Object.keys(panelOptions);
  const [showPanelSelector, setShowPanelSelector] = useState(isMobile ? false : true);
  const [showPanelContent, setShowPanelContent] = useState(true);

  if (!customizer) return (<></>);
  
  let currentIndex = 0;
  panelOptionKeys.forEach((key: string, i: number) => {
    if (key == selectedPanel) {
      currentIndex = i;
    }
  });
  
  return (
    <div className={`${classes.root} ${className ? className : ''}`}>
      <Collapse in={showPanelSelector}>
        <Paper className={classes.panelSelectorContainer}>
          <List component="nav" aria-label="main mailbox folders">
            {panelOptionKeys.map((key: string, i: number) => 
              <ListItem key={`panel-selector-${key}`} button onClick={() => {
                onPanelChanged(key, subPanelOptions[key]);
                setShowPanelSelector(false);
                setShowPanelContent(true);
              }}>
                <ListItemText primary={panelOptions[key]} />
              </ListItem>
            )}
          </List>
          <Divider />
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem button onClick={() => {
              setShowPanelSelector(false);
              onResetPressed();
            }}>
              <ListItemIcon>
                <ReplayIcon />
              </ListItemIcon>
              <ListItemText primary="Start Over" />
            </ListItem>
          </List>
        </Paper>
      </Collapse>

      <div className={classes.panelSwitcherParentContainer}>
        <div>
          <Paper className={classes.panelSwitcherContainer}>
            <ButtonBase className={classes.displayBlock} onClick={() => setShowPanelSelector(!showPanelSelector)}>
              <div className={classes.panelSwitcherSelectedContainer}>
              <Typography className={classes.panelSwitcherSelectedIndexContainer}>{currentIndex + 1}/{panelOptionKeys.length}</Typography>
              <Typography className={classes.panelSwitcherSelectedPanelNameContainer}>{panelOptions[selectedPanel]}</Typography>
              </div>
            </ButtonBase>
            <div className={classes.panelSwitcherButtonsContainer}>
              <div>
                <IconButton
                  onClick={() => setShowPanelSelector(!showPanelSelector)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={() => {
                  panelOptionKeys.forEach((key: string, i: number) => {
                    let nextPanelIndex = currentIndex - 1;
                    if (nextPanelIndex < 0) nextPanelIndex = panelOptionKeys.length - 1;
                    onPanelChanged(panelOptionKeys[nextPanelIndex], subPanelOptions[panelOptionKeys[nextPanelIndex]]);
                    setShowPanelContent(true);
                  });
                }}>
                  <NavigateBeforeIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={() => {
                  panelOptionKeys.forEach((key: string, i: number) => {
                    let nextPanelIndex = currentIndex + 1;
                    if (nextPanelIndex > (panelOptionKeys.length - 1)) nextPanelIndex = 0;
                    onPanelChanged(panelOptionKeys[nextPanelIndex], subPanelOptions[panelOptionKeys[nextPanelIndex]]);
                    setShowPanelContent(true);
                  })
                }}>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
          </Paper>
        </div>
        <Paper className={classes.panelToggleContainer}>
          <IconButton onClick={() => setShowPanelContent(!showPanelContent)}>
            {showPanelContent && <ExpandMoreIcon />}
            {!showPanelContent && <ExpandLessIcon />}
          </IconButton>
        </Paper>
      </div>
      <div className={classes.panelContainer}>
        <Collapse in={showPanelContent && (selectedPanel == 'designName')}>
          <DesignNamePanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'pattern')}>
          <PatternPanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'colors')}>
          <ColorsPanel
            customizer={customizer}
            design={design}
            selectedPanel={selectedSubPanel}
            onPanelChanged={(selectedSubPanel) => onPanelChanged(selectedPanel, selectedSubPanel)}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'logo')}>
          <LogoPanel
            customizer={customizer}
            design={design}
            selectedPanel={selectedSubPanel}
            onPanelChanged={(selectedSubPanel) => onPanelChanged(selectedPanel, selectedSubPanel)}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'text')}>
          <TextPanel
            customizer={customizer}
            design={design}
            selectedPanel={selectedSubPanel}
            onPanelChanged={(selectedSubPanel) => onPanelChanged(selectedPanel, selectedSubPanel)}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'subpart_1')}>
          <SubPartPanel
            customizer={customizer}
            design={design}
            subParts={design.template_data?.subpart_1 ? design.template_data?.subpart_1 : []}
            selectedSubPartId={design.subpart_1}
            onSubPartSelected={subPart => {
              let designClone = Object.assign({}, design);
              if (design.template_data?.exclusive_subpart) {
                designClone.subpart_1 = undefined;
                designClone.subpart_2 = undefined;
                designClone.subpart_3 = undefined;
                designClone.subpart_4 = undefined;
                designClone.subpart_5 = undefined;
              }
              designClone.subpart_1 = subPart?.id;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'subpart_2')}>
          <SubPartPanel
            customizer={customizer}
            design={design}
            subParts={design.template_data?.subpart_2 ? design.template_data?.subpart_2 : []}
            selectedSubPartId={design.subpart_2}
            onSubPartSelected={subPart => {
              let designClone = Object.assign({}, design);
              if (design.template_data?.exclusive_subpart) {
                designClone.subpart_1 = undefined;
                designClone.subpart_2 = undefined;
                designClone.subpart_3 = undefined;
                designClone.subpart_4 = undefined;
                designClone.subpart_5 = undefined;
              }
              designClone.subpart_2 = subPart?.id;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'subpart_3')}>
          <SubPartPanel
            customizer={customizer}
            design={design}
            subParts={design.template_data?.subpart_3 ? design.template_data?.subpart_3 : []}
            selectedSubPartId={design.subpart_3}
            onSubPartSelected={subPart => {
              let designClone = Object.assign({}, design);
              if (design.template_data?.exclusive_subpart) {
                designClone.subpart_1 = undefined;
                designClone.subpart_2 = undefined;
                designClone.subpart_3 = undefined;
                designClone.subpart_4 = undefined;
                designClone.subpart_5 = undefined;
              }
              designClone.subpart_3 = subPart?.id;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'subpart_4')}>
          <SubPartPanel
            customizer={customizer}
            design={design}
            subParts={design.template_data?.subpart_4 ? design.template_data?.subpart_4 : []}
            selectedSubPartId={design.subpart_4}
            onSubPartSelected={subPart => {
              let designClone = Object.assign({}, design);
              if (design.template_data?.exclusive_subpart) {
                designClone.subpart_1 = undefined;
                designClone.subpart_2 = undefined;
                designClone.subpart_3 = undefined;
                designClone.subpart_4 = undefined;
                designClone.subpart_5 = undefined;
              }
              designClone.subpart_4 = subPart?.id;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'subpart_5')}>
          <SubPartPanel
            customizer={customizer}
            design={design}
            subParts={design.template_data?.subpart_5 ? design.template_data?.subpart_5 : []}
            selectedSubPartId={design.subpart_5}
            onSubPartSelected={subPart => {
              let designClone = Object.assign({}, design);
              if (design.template_data?.exclusive_subpart) {
                designClone.subpart_1 = undefined;
                designClone.subpart_2 = undefined;
                designClone.subpart_3 = undefined;
                designClone.subpart_4 = undefined;
                designClone.subpart_5 = undefined;
              }
              designClone.subpart_5 = subPart?.id;
              onDesignChanged(designClone);
            }}
          />
        </Collapse>
      </div>
    </div>
  );
}

export default CustomizerPanel;