import React, {
  useState,
  useEffect
} from 'react';

import {
  BaseAPI,
  APIError,
} from './BaseAPI';

class CustomizerDataAPI extends BaseAPI {
  path = 'customizer-data/'
}

class DesignDataAPI extends BaseAPI {
  path = 'design/save/'
}

let customizerAPI: CustomizerDataAPI;
function getCustomizerAPI() {
  if (!customizerAPI) customizerAPI = new CustomizerDataAPI();
  return customizerAPI;
}

let designAPI: DesignDataAPI;
function getDesignAPI() {
  if (!designAPI) designAPI = new DesignDataAPI();
  return designAPI;
}

function useCustomizerData(path='') : [CustomizerData|undefined, boolean, APIError|undefined, ((data: any) => Promise<any[]>), boolean] {
  const [customizerData, setCustomizerData] = useState(undefined as CustomizerData|undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(<undefined|APIError> undefined);
  const [saveDesignLoading, setSaveDesignLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const api = getCustomizerAPI();
    try {
      const data = await api.get(path);
      setCustomizerData(data as CustomizerData);
      setError(undefined);

      if ((window as any).APP_CONFIG) {
        (window as any).APP_CONFIG.renderTagline = (data as CustomizerData).site_setting.render_tagline;
        (window as any).APP_CONFIG.skipWatermark = !(data as CustomizerData).site_setting.show_watermark;
        (window as any).APP_CONFIG.skipBottomWatermark = !(data as CustomizerData).site_setting.show_bottom_watermark;
        (window as any).APP_CONFIG.noOverlappingWatermark = (data as CustomizerData).site_setting.no_overlapping_watermark;
      }
    } catch (error) {
      setError(error as APIError);
    }

    setLoading(false);
  }

  const saveDesign = async (data: DesignData) => {
    let success = false;
    setSaveDesignLoading(true);
    let formData = new FormData();
    if (data.subpart_1) formData.append('subpart_1', data.subpart_1);
    if (data.subpart_2) formData.append('subpart_2', data.subpart_2);
    if (data.subpart_3) formData.append('subpart_3', data.subpart_3);
    if (data.subpart_4) formData.append('subpart_4', data.subpart_4);
    if (data.subpart_5) formData.append('subpart_5', data.subpart_5);

    if (data.logo_1_file) formData.append('logo_1_file', data.logo_1_file);
    if (data.logo_2_file) formData.append('logo_2_file', data.logo_2_file);
    if (data.logo_3_file) formData.append('logo_3_file', data.logo_3_file);
    if (data.logo_4_file) formData.append('logo_4_file', data.logo_4_file);
    if (data.logo_5_file) formData.append('logo_5_file', data.logo_5_file);

    if (data.rendered_image) formData.append('rendered_image', data.rendered_image, 'render.jpg');

    formData.append('design_name', data.design_name);
    formData.append('notes', data.notes);

    formData.append('text_1', data.text_1);
    formData.append('text_fill_color_1', data.text_fill_color_1);
    formData.append('text_stroke_color_1', data.text_stroke_color_1);
    if (data.text_font_id_1) formData.append('text_font_id_1', data.text_font_id_1);

    formData.append('text_2', data.text_2);
    formData.append('text_fill_color_2', data.text_fill_color_2);
    formData.append('text_stroke_color_2', data.text_stroke_color_2);
    if (data.text_font_id_2) formData.append('text_font_id_2', data.text_font_id_2);

    formData.append('text_3', data.text_3);
    formData.append('text_fill_color_3', data.text_fill_color_3);
    formData.append('text_stroke_color_3', data.text_stroke_color_3);
    if (data.text_font_id_3) formData.append('text_font_id_3', data.text_font_id_3);

    formData.append('text_4', data.text_4);
    formData.append('text_fill_color_4', data.text_fill_color_4);
    formData.append('text_stroke_color_4', data.text_stroke_color_4);
    if (data.text_font_id_4) formData.append('text_font_id_4', data.text_font_id_4);

    formData.append('text_5', data.text_5);
    formData.append('text_fill_color_5', data.text_fill_color_5);
    formData.append('text_stroke_color_5', data.text_stroke_color_5);
    if (data.text_font_id_5) formData.append('text_font_id_5', data.text_font_id_5);

    formData.append('text_6', data.text_6);
    formData.append('text_fill_color_6', data.text_fill_color_6);
    formData.append('text_stroke_color_6', data.text_stroke_color_6);
    if (data.text_font_id_6) formData.append('text_font_id_6', data.text_font_id_6);

    formData.append('template', data.template);
    formData.append('color_1', data.color_1);
    formData.append('color_2', data.color_2);
    formData.append('color_3', data.color_3);
    formData.append('color_4', data.color_4);
    formData.append('color_5', data.color_5);

    formData.append('customer_name', data.customer_name);
    formData.append('customer_email', data.customer_email);
    if (data.customer_add_to_list) {
      formData.append('customer_add_to_list', '1');
    }

    if (data.extra_recipients) {
      formData.append(`extra_recipient_email_count`, `${data.extra_recipients.length}`);
      data.extra_recipients.forEach((recipient: any, i: number) => {
        formData.append(`extra_recipient_email_${i}`, recipient);
      })
    }

    const api = getDesignAPI();
    try {
      const [result, response] = await api.post(formData, path);
      if (result && result.success) {
        success = true;
      }
      setSaveDesignLoading(false);
      if (success) return [success, result, undefined];
      return [success, result, {status: 1200}];
    } catch (error) {
      setSaveDesignLoading(false);
      return [false, {}, error];
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return [
    customizerData,
    loading,
    error,
    saveDesign,
    saveDesignLoading,
  ];
}

export {
  getCustomizerAPI,
  getDesignAPI,
  useCustomizerData,
};