import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Grid,
  Collapse,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

}));

const getGroupsFromTemplates = (templates: Template[]) => {
  const groups: TemplateGroup[] = [];
  templates.forEach((template) => {
    template.groups.forEach((group) => {
      if (groups.filter((g) => g.slug === group.slug).length === 0) {
        groups.push(group);
      }
    });
  });

  groups.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return groups;
}

export default (props: {
  onGroupSelected: (group: TemplateGroup|null) => void;
  shown: boolean;
  templates: Template[];
}) => {
  const classes = useStyles();

  const groups = getGroupsFromTemplates(props.templates);

  return (
    <Dialog onClose={() => props.onGroupSelected(null)} aria-labelledby="save-dialog-title" open={props.shown} style={{zIndex: 999999}}>
      <DialogContent>
        <DialogContentText>
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem button onClick={() => props.onGroupSelected(null)}>
              <ListItemText primary="All" />
            </ListItem>
            {groups.map(group => (
              <ListItem key={group.slug} button onClick={() => props.onGroupSelected(group)}>
                <ListItemText primary={group.name} />
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
