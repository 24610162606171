import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Link,
  Typography,
  Button,
  Grow,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import ResizeIcon from '@material-ui/icons/ZoomOutMap';
import RotateIcon from '@material-ui/icons/RotateRight';

import letterSpaceAdjustment from '../utils/letterSpaceAdjustment';
import renderJerseyToCanvas from '../utils/renderJerseyToCanvas';
import Breadcrumbs from './Breadcrumbs';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  renderedImageContainer: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    whiteSpace: 'nowrap',
  },
  renderedImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
    pointerEvents: 'none'
  },
  loadingIndicator: {
    position: 'absolute',
    top: 20,
    right: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  breadcrumbsContainer: {
    position: 'absolute',
    top: 0,
    left: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  interactiveTextItem: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  interactiveTextItemActive: {
    position: 'relative',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0.2)',
    border: 'solid 1px #ababab',
    cursor: 'move',
    whiteSpace: 'nowrap',
  },
  interactiveImageItem: {
    backgroundColor: 'rgba(0,0,0,0)',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  interactiveImageItemActive: {
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.2)',
    border: 'solid 1px #ababab',
    cursor: 'move',
    whiteSpace: 'nowrap',
  },
  resizeBox: {
    width: 12,
    height: 12,
    border: 'solid 1px #fff',
    // borderRadius: 12,
    backgroundColor: '#000',
  },
  rotateBox: {
    width: 12,
    height: 12,
    border: 'solid 1px #fff',
    borderRadius: 12,
    backgroundColor: '#000',
  },
  closeBox: {
    width: 12,
    height: 12,
    border: 'solid 1px #fff',
    borderRadius: 12,
    backgroundColor: '#000',
  }
}));


const StyledCircularProgress = withStyles((theme) => ({
  root: {
    color: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
  },
}))(CircularProgress);


interface InteractiveRendererProps {
  siteSetting?: SiteSetting;
  design: Design;
  topBannerShown: boolean;
  onDesignChanged: (design: Design) => void;
  onResetPressed: () => void;
  selectedPanel: string;
  selectedSubPanel: string;
  onPanelChanged: (panel: string, subpanel: string) => void;
}

function InteractiveRenderer({
  siteSetting,
  design,
  topBannerShown,
  onDesignChanged,
  onResetPressed,
  selectedPanel,
  selectedSubPanel,
  onPanelChanged
}: InteractiveRendererProps) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isRendering, setIsRendering] = useState(true);
  const heightOffset = topBannerShown ? 200 : 200 - 44;
  const [renderedImage, setRenderedImage] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=');
  const [overlayShadowImage, setOverlayShadowImage] = useState(undefined as string|undefined);
  const renderedImageHeight = (height - heightOffset) < 260 ? 260 : (height - heightOffset);
  const renderJerseyTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  useEffect(() => {
    if (renderJerseyTimeoutHandle.current) {
      clearTimeout(renderJerseyTimeoutHandle.current);
      renderJerseyTimeoutHandle.current = null;
      console.log('render was already scheduled');
    }
    renderJerseyTimeoutHandle.current = setTimeout(async () => {
      // console.log('user design data updated', design);
      renderJerseyTimeoutHandle.current = null;
      setIsRendering(true);
      const render = await renderJerseyToCanvas(design, {
        main: 'design_generator',
        part1: 'design_generator1_main',
        part2: 'design_generator2_main',
        part3: 'design_generator3_main',
        overlay_shadow: 'design_generator_overlay_shadow',
        overlay_shadow_cropped: 'design_generator_overlay_shadow_cropped',
      });

      setRenderedImage(render.render);
      setOverlayShadowImage(render.overlayShadow);
      setIsRendering(false);
    }, 100);
  }, [design]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const iosOrientationChange = () => {
      window.addEventListener('resize', handleResize);
      setTimeout(() => {
        window.removeEventListener('resize', handleResize);
      }, 300);
    }
    
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    // on safari mobile, only resize on orientation change
    if (iOSSafari) {
      window.addEventListener('orientationchange', iosOrientationChange);
    }
    else {
      window.addEventListener('resize', handleResize);
    }
    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.addEventListener('orientationchange', iosOrientationChange);
    }
  }, []);

  let breadcrumbs = [];
  if (siteSetting && siteSetting.breadcrumbs && (siteSetting.breadcrumbs as any).contents) {
    breadcrumbs = (siteSetting.breadcrumbs as any).contents as any[];
  }

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbsContainer}>
        <Grow in={breadcrumbs.length > 0}>
          <Breadcrumbs contents={breadcrumbs} />
        </Grow>
      </div>
      <div className={classes.loadingIndicator}>
        <Grow in={isRendering}>
          <StyledCircularProgress color="secondary"/>
        </Grow>
      </div>
      {!!siteSetting &&
      <InteractiveOverlay
        width={width}
        height={renderedImageHeight}
        image={renderedImage}
        overlayShadowImage={overlayShadowImage}
        siteSetting={siteSetting}
        design={design}
        selectedPanel={selectedPanel}
        selectedSubPanel={selectedSubPanel}
        onPanelChanged={onPanelChanged}
        onDesignChanged={onDesignChanged}
      />}
    </div>
  );
}


function getFullTextDetail(design: Design, textInfo: any) {
  const data: any = {...textInfo}
  if (textInfo.text_type == 'text_1') {
    data['text'] = design.text_1 ? design.text_1 : (design.template_data?.text_default_1 ? design.template_data?.text_default_1 : '');
    data['font_id'] = design.text_font_id_1;
    data['font_name'] = design.text_font_name_1;
    data['letter_spacing'] = design.text_font_letter_spacing_1;
    data['text_fill_color'] = design.text_fill_color_1;
    data['text_stroke_color'] = design.text_stroke_color_1;
    data['text_position'] = design.text_position_1;
  }
  if (textInfo.text_type == 'text_2') {
    data['text'] = design.text_2 ? design.text_2 : (design.template_data?.text_default_2 ? design.template_data?.text_default_2 : '');
    data['font_id'] = design.text_font_id_2;
    data['font_name'] = design.text_font_name_2;
    data['letter_spacing'] = design.text_font_letter_spacing_2;
    data['text_fill_color'] = design.text_fill_color_2;
    data['text_stroke_color'] = design.text_stroke_color_2;
    data['text_position'] = design.text_position_2;
  }
  if (textInfo.text_type == 'text_3') {
    data['text'] = design.text_3 ? design.text_3 : (design.template_data?.text_default_3 ? design.template_data?.text_default_3 : '');
    data['font_id'] = design.text_font_id_3;
    data['font_name'] = design.text_font_name_3;
    data['letter_spacing'] = design.text_font_letter_spacing_3;
    data['text_fill_color'] = design.text_fill_color_3;
    data['text_stroke_color'] = design.text_stroke_color_3;
    data['text_position'] = design.text_position_3;
  }
  if (textInfo.text_type == 'text_4') {
    data['text'] = design.text_4 ? design.text_4 : (design.template_data?.text_default_4 ? design.template_data?.text_default_4 : '');
    data['font_id'] = design.text_font_id_4;
    data['font_name'] = design.text_font_name_4;
    data['letter_spacing'] = design.text_font_letter_spacing_4;
    data['text_fill_color'] = design.text_fill_color_4;
    data['text_stroke_color'] = design.text_stroke_color_4;
    data['text_position'] = design.text_position_4;
  }
  if (textInfo.text_type == 'text_5') {
    data['text'] = design.text_5 ? design.text_5 : (design.template_data?.text_default_5 ? design.template_data?.text_default_5 : '');
    data['font_id'] = design.text_font_id_5;
    data['font_name'] = design.text_font_name_5;
    data['letter_spacing'] = design.text_font_letter_spacing_5;
    data['text_fill_color'] = design.text_fill_color_5;
    data['text_stroke_color'] = design.text_stroke_color_5;
    data['text_position'] = design.text_position_5;
  }
  if (textInfo.text_type == 'text_6') {
    data['text'] = design.text_6 ? design.text_6 : (design.template_data?.text_default_6 ? design.template_data?.text_default_6 : '');
    data['font_id'] = design.text_font_id_6;
    data['font_name'] = design.text_font_name_6;
    data['letter_spacing'] = design.text_font_letter_spacing_6;
    data['text_fill_color'] = design.text_fill_color_6;
    data['text_stroke_color'] = design.text_stroke_color_6;
    data['text_position'] = design.text_position_6;
  }
  return data;
}

function getFullIconDetail(design: Design, iconInfo: any) {
  const data: any = {...iconInfo}
  if (iconInfo.image == 'logo-1') {
    data['image_content'] = design.logo_1;
    data['image_position'] = design.logo_position_1;
  }
  if (iconInfo.image == 'logo-2') {
    data['image_content'] = design.logo_2;
    data['image_position'] = design.logo_position_2;
  }
  if (iconInfo.image == 'logo-3') {
    data['image_content'] = design.logo_3;
    data['image_position'] = design.logo_position_3;
  }
  if (iconInfo.image == 'logo-4') {
    data['image_content'] = design.logo_4;
    data['image_position'] = design.logo_position_4;
  }
  if (iconInfo.image == 'logo-5') {
    data['image_content'] = design.logo_5;
    data['image_position'] = design.logo_position_5;
  }
  return data;
}

interface InteractiveTextItemProps {
  item: any;
  onClick: () => void;
  onPositionChanged?: (position: DesignObjectPosition) => void;
  onRemove?: () => void;
  active?: boolean;
  zoom: number;
}

function InteractiveTextItem({item, onClick, onPositionChanged, active, zoom, onRemove}: InteractiveTextItemProps) {
  const containerRef = useRef(null as HTMLDivElement|null);
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPos, setDragStartPos] = useState([0, 0]);
  const [pointerPos, setPointerPos] = useState([0, 0]);

  const [isResizing, setIsResizing] = useState(false);
  const [resizingDirection, setResizingDirection] = useState(1);
  const [isRotating, setIsRotating] = useState(false);
  const [rotatingSideX, setRotatingSideX] = useState(1);
  const [rotatingSideY, setRotatingSideY] = useState(1);
  let yOffset = 0;
  if ((window as any).APP_CONFIG.noOverlappingWatermark) {
    yOffset = 100;
  }

  let delta: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
  delta = Object.assign({}, delta, item.text_position);

  const calculateCurrentAngle = (pointerPosX: number, pointerPosY: number, dragStartPosX: number, dragStartPosY: number, w: number, h: number, rotatingSideX: number, rotatingSideY: number) => {
    let currentAngle = 0;

    let centerX = dragStartPosX - rotatingSideX * (w/2);
    let centerY = dragStartPosY + rotatingSideY * (h/2);

    if (containerRef.current) {
      let rect = containerRef.current.getBoundingClientRect();
      centerX = rect.x + rect.width / 2;
      centerY = rect.y + rect.height / 2;
    }

    let originDx = dragStartPosX - centerX;
    let originDy = dragStartPosY - centerY;
    let originAngle = Math.atan(originDy / originDx) * (180/Math.PI);

    let dy = (pointerPosY - centerY);
    let dx = (pointerPosX - centerX);

    let angle = (Math.atan(dy / dx) * (180/Math.PI));

    if (Math.abs(dy) > 0) {
      if (dx < 0) {
        angle += 180;
      }
      if (originDx < 0) {
        originAngle += 180;
      }
      currentAngle = angle - originAngle;
    }
    else {
      currentAngle = originAngle;
    }

    return currentAngle;
  }

  useEffect(() => {
    if (!active) return;
  
    let movementHandler = (clientX: number, clientY: number) => {
      if (active && (isDragging || isResizing || isRotating)) {
        setPointerPos([clientX, clientY]);
      }
    };

    let upHandler = (clientX: number, clientY: number) => {
      if (active && isDragging) {
        setIsDragging(false);

        if (onPositionChanged) {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0) + (clientX - dragStartPos[0]) / zoom;
          pos.y = (pos.y ? pos.y : 0) + (clientY - dragStartPos[1]) / zoom;
          onPositionChanged(pos);
        }
        console.log('stop dragging');
      }
      if (active && isResizing) {
        setIsResizing(false);

        if (onPositionChanged) {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.h = (pos.h ? pos.h : 0) + ((clientY - dragStartPos[1]) / zoom) * 2 * resizingDirection;
          onPositionChanged(pos);
        }
        console.log('stop resizing');
      }
      if (active && isRotating) {
        setIsRotating(false);
        if (onPositionChanged && containerRef.current) {
          let currentAngle = calculateCurrentAngle(clientX, clientY, dragStartPos[0], dragStartPos[1], containerRef.current.clientWidth, containerRef.current.clientHeight, rotatingSideX, rotatingSideY)
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.r = ((pos.r ? pos.r : 0) + currentAngle) % 360;
          onPositionChanged(pos);
        }
        console.log('stop rotating');
      }
    };

    let mouseMovementHandler = (e: MouseEvent) => {
      movementHandler(e.clientX, e.clientY);
      if (active && (isDragging || isResizing || isRotating)) {
        e.stopPropagation();
      }
    };

    const touchMovementHandler = (e: TouchEvent) => {
      if (!e.touches.length) return;

      const t = e.touches[0];
      movementHandler(t.clientX, t.clientY);
      (window as any)._touchX = t.clientX;
      (window as any)._touchY = t.clientY;
      if (active && (isDragging || isResizing || isRotating)) {
        e.stopPropagation();
      }
    }

    let mouseUpHandler = (e: MouseEvent) => {
      return upHandler(e.clientX, e.clientY);
    };

    let touchUpHandler = (e: TouchEvent) => {
      return upHandler((window as any)._touchX, (window as any)._touchY);
    };

    window.document.addEventListener('mousemove', mouseMovementHandler);
    window.document.body.classList.add('no-selection');
    window.document.addEventListener('mouseup', mouseUpHandler);

    window.document.addEventListener('touchmove', touchMovementHandler);
    window.document.addEventListener('touchend', touchUpHandler);

    return () => {
      window.document.removeEventListener('mousemove', mouseMovementHandler);
      window.document.removeEventListener('mouseup', mouseUpHandler);
      window.document.body.classList.remove('no-selection');

      window.document.removeEventListener('touchmove', touchMovementHandler);
      window.document.removeEventListener('touchend', touchUpHandler);
    }
  }, [active, isDragging, isResizing, resizingDirection, isRotating, containerRef.current, rotatingSideX, rotatingSideY]);

  let top = (yOffset * zoom) + (item.y + delta.y - (delta.h ? delta.h : 0) - (1 * item.font_size)) * zoom;
  let left = (item.x + delta.x) * zoom;
  let fontSize = (item.font_size ? item.font_size : 0) + (delta.h ? delta.h : 0);
  let customHeight = 0;
  if (item.text_align === 'center' && containerRef.current) {
    left = ((item.x + delta.x) * zoom) - (containerRef.current.offsetWidth / 2);
  }

  if (delta.effect === 'arc' && containerRef.current) {
    customHeight = containerRef.current.offsetWidth;
    top = (item.y + delta.y - (delta.h ? delta.h : 0)) * zoom - (containerRef.current.offsetWidth / 2);
  }

  if (delta.effect === 'vertical' && containerRef.current) {
    customHeight = (fontSize * item.text.length) * zoom;
    top = (item.y + delta.y - (delta.h ? delta.h : 0)) * zoom - (customHeight / 2);
  }

  if (isResizing) {
    fontSize = (item.font_size ? item.font_size : 0) + (delta.h ? delta.h : 0) + ((pointerPos[1] - dragStartPos[1]) / zoom) * 2 * resizingDirection
    top -= (pointerPos[1] - dragStartPos[1]) * 2 * resizingDirection;
  }

  let currentAngle = 0;
  if (isRotating && containerRef.current) {
    currentAngle = calculateCurrentAngle(pointerPos[0], pointerPos[1], dragStartPos[0], dragStartPos[1], containerRef.current.clientWidth, containerRef.current.clientHeight, rotatingSideX, rotatingSideY)
  }
  let rotate = 'rotate(0deg)';

  if (item.angle || currentAngle || delta.r) {
    let angle = (item.angle ? item.angle : 0) + (delta.r ? delta.r : 0) + currentAngle;
    rotate = `rotate(${angle}deg)`;
  }

  let isMobile = window.innerHeight < 580;

  if (!item.text) return (<></>);

  let textContent = letterSpaceAdjustment(item.text, item.letter_spacing ? item.letter_spacing : 0);
  if (delta.effect === 'arc') {
    textContent = textContent.substr(0, textContent.length * .667);
  }if (delta.effect === 'vertical') {
    textContent = item.text.length > 0 ? item.text[0] : ' ';
  }
  return (
    <div
      ref={containerRef}
      tabIndex={-1}
      style={{
        position: 'absolute',
        top: (isDragging ? top - (dragStartPos[1]-pointerPos[1]) : top),
        left: (isDragging ? left - (dragStartPos[0]-pointerPos[0]) : left),
        transform: rotate,
        zIndex: !isMobile ? (active ? 9999 : 1) : 0,
        userSelect: 'none',
      }}
      onClick={() => {
        if (!active) {
          onClick();
        }
      }}
      onMouseDown={(e) => {
        if (active) {
          setDragStartPos([e.clientX, e.clientY]);
          setPointerPos([e.clientX, e.clientY]);
          setIsDragging(true);
        }
      }}
      onTouchStart={(e) => {
        if (active) {
          if (!e.touches.length) return;
          const t = e.touches[0];
          setDragStartPos([t.clientX, t.clientY]);
          setPointerPos([t.clientX, t.clientY]);
          setIsDragging(true);
        }
      }}
      onContextMenu={(e) => {
        if (active) e.preventDefault();
      }}
      onKeyUp={(e) => {
        if (!onPositionChanged) return;
        if (e.key === 'ArrowRight') {
            let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
            pos = Object.assign({}, pos, delta);
            pos.x = (pos.x ? pos.x : 0) + 3 / zoom;
            pos.y = (pos.y ? pos.y : 0);
            onPositionChanged(pos);
        }
        else if (e.key === 'ArrowLeft') {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0) - 3 / zoom;
          pos.y = (pos.y ? pos.y : 0);
          onPositionChanged(pos);
        }
        else if (e.key === 'ArrowUp') {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0);
          pos.y = (pos.y ? pos.y : 0) - 3 / zoom;
          onPositionChanged(pos);
        }
        else if (e.key === 'ArrowDown') {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0);
          pos.y = (pos.y ? pos.y : 0) + 3 / zoom;
          onPositionChanged(pos);
        }
        else if (e.key === 'Backspace') {
          if (onRemove && window.confirm('Remove this item?')) onRemove();
        }
        else if (e.key === 'Delete') {
          if (onRemove && window.confirm('Remove this item?')) onRemove();
        }
      }}
    >
      <div
        className={active ? classes.interactiveTextItemActive : classes.interactiveTextItem}
        style={{
        }}
      >
        <div style={{
          paddingLeft: 10,
          paddingRight: 10,
          fontFamily: `"${item.font_name}"`,
          textAlign: item.text_align,
          fontSize: fontSize > 20 ? (fontSize * zoom) : (20 * zoom),
          height: customHeight ? customHeight : 'auto',
        }}>{textContent}</div>
        {active &&
        <div
          className={classes.closeBox}
          style={{
            position: 'absolute',
            top: -6,
            left: -6,
            width: 12,
            height: 12,
            cursor: 'pointer',
            backgroundColor: 'red',
          }}
          onClick={(e) => {
            if (active) {
              if (onRemove && window.confirm('Remove this item?')) onRemove();
            }
          }}
        ><CloseIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
        {active &&
        <div
          className={classes.resizeBox}
          style={{
            position: 'absolute',
            bottom: -6,
            left: -6,
            width: 12,
            height: 12,
            cursor: 'nesw-resize',
          }}
          onMouseDown={(e) => {
            if (active) {
              setDragStartPos([e.clientX, e.clientY]);
              setPointerPos([e.clientX, e.clientY]);
              setIsResizing(true);
              setResizingDirection(1);
              e.stopPropagation();
              console.log('start resizing');
            }
          }}
          onTouchStart={(e) => {
            if (active) {
              if (!e.touches.length) return;
              const t = e.touches[0];
              setDragStartPos([t.clientX, t.clientY]);
              setPointerPos([t.clientX, t.clientY]);
              setIsResizing(true);
              setResizingDirection(1);
              e.stopPropagation();
              console.log('start resizing');
            }
          }}
        ><ResizeIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
        {active &&
        <div
          className={classes.resizeBox}
          style={{
            position: 'absolute',
            bottom: -6,
            right: -6,
            width: 12,
            height: 12,
            cursor: 'nwse-resize',
          }}
          onMouseDown={(e) => {
            if (active) {
              setDragStartPos([e.clientX, e.clientY]);
              setPointerPos([e.clientX, e.clientY]);
              setIsResizing(true);
              setResizingDirection(1);
              e.stopPropagation();
              console.log('start resizing');
            }
          }}
          onTouchStart={(e) => {
            if (active) {
              if (!e.touches.length) return;
              const t = e.touches[0];
              setDragStartPos([t.clientX, t.clientY]);
              setPointerPos([t.clientX, t.clientY]);
              setIsResizing(true);
              setResizingDirection(1);
              e.stopPropagation();
              console.log('start resizing');
            }
          }}
        ><ResizeIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
        {active && 
        <div
          className={classes.rotateBox}
          style={{
            position: 'absolute',
            top: -6,
            right: -6,
            width: 12,
            height: 12,
            cursor: 'grabbing'
          }}
          onMouseDown={(e) => {
            if (active) {
              setDragStartPos([e.clientX, e.clientY]);
              setPointerPos([e.clientX, e.clientY]);
              setIsRotating(true);
              setRotatingSideX(-1);
              setRotatingSideY(-1);
              e.stopPropagation();
              console.log('start rotating');
            }
          }}
          onTouchStart={(e) => {
            if (active) {
              if (!e.touches.length) return;
              const t = e.touches[0];
              setDragStartPos([t.clientX, t.clientY]);
              setPointerPos([t.clientX, t.clientY]);
              setIsRotating(true);
              setRotatingSideX(-1);
              setRotatingSideY(-1);
              e.stopPropagation();
              console.log('touch: start rotating');
            }
          }}
        ><RotateIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
      </div>
    </div>
  );
}


interface InteractiveImageItemProps {
  item: any;
  onClick: () => void;
  onPositionChanged?: (position: DesignObjectPosition) => void;
  onRemove?: () => void;
  active?: boolean;
  zoom: number;
}

function InteractiveImageItem({item, onClick, onPositionChanged, active, zoom, onRemove}: InteractiveImageItemProps) {
  const containerRef = useRef(null as HTMLDivElement|null);
  const imageRef = useRef(null as HTMLImageElement|null);
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPos, setDragStartPos] = useState([0, 0]);
  const [pointerPos, setPointerPos] = useState([0, 0]);

  const [isResizing, setIsResizing] = useState(false);
  const [resizingDirection, setResizingDirection] = useState(1);
  const [isRotating, setIsRotating] = useState(false);
  const [rotatingSideX, setRotatingSideX] = useState(1);
  const [rotatingSideY, setRotatingSideY] = useState(1);
  let yOffset = 0;
  if ((window as any).APP_CONFIG.noOverlappingWatermark) {
    yOffset = 100;
  }

  let delta: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
  delta = Object.assign({}, delta, item.image_position);

  const calculateCurrentAngle = (pointerPosX: number, pointerPosY: number, dragStartPosX: number, dragStartPosY: number, w: number, h: number, rotatingSideX: number, rotatingSideY: number) => {
    let currentAngle = 0;

    let centerX = dragStartPosX - rotatingSideX * (w/2);
    let centerY = dragStartPosY + rotatingSideY * (h/2);

    if (containerRef.current) {
      let rect = containerRef.current.getBoundingClientRect();
      centerX = rect.x + rect.width / 2;
      centerY = rect.y + rect.height / 2;
    }

    let originDx = dragStartPosX - centerX;
    let originDy = dragStartPosY - centerY;
    let originAngle = Math.atan(originDy / originDx) * (180/Math.PI);

    let dy = (pointerPosY - centerY);
    let dx = (pointerPosX - centerX);

    let angle = (Math.atan(dy / dx) * (180/Math.PI));

    if (Math.abs(dy) > 0) {
      if (dx < 0) {
        angle += 180;
      }
      if (originDx < 0) {
        originAngle += 180;
      }
      currentAngle = angle - originAngle;
    }
    else {
      currentAngle = originAngle;
    }

    return currentAngle;
  }

  useEffect(() => {
    if (!active) return;

    let movementHandler = (clientX: number, clientY: number) => {
      if (active && (isDragging || isResizing || isRotating)) {
        setPointerPos([clientX, clientY]);
      }
    }

    let upHandler = (clientX: number, clientY: number) => {
      if (active && isDragging) {
        setIsDragging(false);

        if (onPositionChanged) {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0) + (clientX - dragStartPos[0]) / zoom;
          pos.y = (pos.y ? pos.y : 0) + (clientY - dragStartPos[1]) / zoom;
          onPositionChanged(pos);
        }
        console.log('stop dragging');
      }
      if (active && isResizing) {
        setIsResizing(false);

        if (onPositionChanged) {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          let ww = imageRef.current ? imageRef.current.width : width;
          let hh = imageRef.current ? imageRef.current.height : height;
          pos.w = (pos.w ? pos.w : 0) + ((clientX - dragStartPos[0]) / zoom) * 2 * resizingDirection;
          pos.x = (pos.x ? pos.x : 0);
          pos.y = (pos.y ? pos.y : 0) - ((clientX - dragStartPos[0]) / zoom) * resizingDirection * (hh/ww);
          onPositionChanged(pos);
        }
        console.log('stop resizing');
      }
      if (active && isRotating) {
        setIsRotating(false);
        if (onPositionChanged && containerRef.current) {
          let currentAngle = calculateCurrentAngle(clientX, clientY, dragStartPos[0], dragStartPos[1], containerRef.current.clientWidth, containerRef.current.clientHeight, rotatingSideX, rotatingSideY)
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.r = ((pos.r ? pos.r : 0) + currentAngle) % 360;
          onPositionChanged(pos);
        }
        console.log('stop rotating');
      }
    }

    let mouseMovementHandler = (e: MouseEvent) => {
      movementHandler(e.clientX, e.clientY);
      if (active && (isDragging || isResizing || isRotating)) {
        e.stopPropagation();
      }
    };

    const touchMovementHandler = (e: TouchEvent) => {
      if (!e.touches.length) return;

      const t = e.touches[0];
      movementHandler(t.clientX, t.clientY);
      (window as any)._touchX = t.clientX;
      (window as any)._touchY = t.clientY;
      if (active && (isDragging || isResizing || isRotating)) {
        e.stopPropagation();
      }
    }

    let mouseUpHandler = (e: MouseEvent) => {
      return upHandler(e.clientX, e.clientY);
    };

    let touchUpHandler = (e: TouchEvent) => {
      return upHandler((window as any)._touchX, (window as any)._touchY);
    };

    window.document.addEventListener('mousemove', mouseMovementHandler);
    window.document.body.classList.add('no-selection');
    window.document.addEventListener('mouseup', mouseUpHandler);

    window.document.addEventListener('touchmove', touchMovementHandler);
    window.document.addEventListener('touchend', touchUpHandler);

    return () => {
      window.document.removeEventListener('mousemove', mouseMovementHandler);
      window.document.removeEventListener('mouseup', mouseUpHandler);
      window.document.body.classList.remove('no-selection');

      window.document.removeEventListener('touchmove', touchMovementHandler);
      window.document.removeEventListener('touchend', touchUpHandler);
    }
  }, [active, isDragging, isResizing, resizingDirection, isRotating, rotatingSideX, rotatingSideY, containerRef.current]);

  let top = (yOffset * zoom) + (item.y + delta.y) * zoom;
  let left = (item.x + delta.x - ((delta.w ? delta.w : 0) / 2)) * zoom;
  let width = (item.w + delta.w) * zoom;
  let height = (imageRef.current ? imageRef.current.height : 0)

  if (isDragging) {
    top = top - (dragStartPos[1]-pointerPos[1]);
    left = left - (dragStartPos[0]-pointerPos[0]);
  }
  if (isResizing) {
    let ww = imageRef.current ? imageRef.current.width : width;
    top = top + (dragStartPos[0]-pointerPos[0]) * resizingDirection * (height / ww);
    left = left + (dragStartPos[0]-pointerPos[0]) * resizingDirection;
    width = width - (dragStartPos[0]-pointerPos[0])*2 * resizingDirection;
  }

  let currentAngle = 0;
  if (isRotating && containerRef.current) {
    currentAngle = calculateCurrentAngle(pointerPos[0], pointerPos[1], dragStartPos[0], dragStartPos[1], containerRef.current.clientWidth, containerRef.current.clientHeight, rotatingSideX, rotatingSideY)
  }
  let rotate = 'rotate(0deg)';

  if (item.angle || currentAngle || delta.r) {
    let angle = (item.angle ? item.angle : 0) + (delta.r ? delta.r : 0) + currentAngle;
    rotate = `rotate(${angle}deg)`;
  }

  let isMobile = window.innerHeight < 580;

  if (!item.image_content) return (<></>);

  return (
    <div
      ref={containerRef}
      tabIndex={-1}
      style={{
        position: 'absolute',
        top: top - 6,
        left: left - 6,
        transform: rotate,
        cursor: 'pointer',
        zIndex: !isMobile ? (active ? 9999 : 1) : 0,
        userSelect: 'none',
      }}
      onClick={() => {
        if (!active) {
          onClick();
        }
      }}
      onMouseDown={(e) => {
        if (active) {
          setDragStartPos([e.clientX, e.clientY]);
          setPointerPos([e.clientX, e.clientY]);
          setIsDragging(true);
          console.log('start dragging')
        }
      }}
      onTouchStart={(e) => {
        if (active) {
          if (!e.touches.length) return;

          const t = e.touches[0];

          (window as any)._touchX = t.clientX;
          (window as any)._touchY = t.clientY;
          
          setDragStartPos([t.clientX, t.clientY]);
          setPointerPos([t.clientX, t.clientY]);
          setIsDragging(true);
          console.log('touch: start dragging')
        }
      }}
      onContextMenu={(e) => {
        if (active) e.preventDefault();
      }}
      onKeyUp={(e) => {
        if (!onPositionChanged) return;
        if (e.key === 'ArrowRight') {
            let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
            pos = Object.assign({}, pos, delta);
            pos.x = (pos.x ? pos.x : 0) + 3 / zoom;
            pos.y = (pos.y ? pos.y : 0);
            onPositionChanged(pos);
        }
        else if (e.key === 'ArrowLeft') {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0) - 3 / zoom;
          pos.y = (pos.y ? pos.y : 0);
          onPositionChanged(pos);
        }
        else if (e.key === 'ArrowUp') {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0);
          pos.y = (pos.y ? pos.y : 0) - 3 / zoom;
          onPositionChanged(pos);
        }
        else if (e.key === 'ArrowDown') {
          let pos: DesignObjectPosition = {x: 0, y: 0, w: 0, h: 0, r: 0};
          pos = Object.assign({}, pos, delta);
          pos.x = (pos.x ? pos.x : 0);
          pos.y = (pos.y ? pos.y : 0) + 3 / zoom;
          onPositionChanged(pos);
        }
        else if (e.key === 'Backspace') {
          if (onRemove && window.confirm('Remove this item?')) onRemove();
        }
        else if (e.key === 'Delete') {
          if (onRemove && window.confirm('Remove this item?')) onRemove();
        }
      }}
    >
      <div
        className={active ? classes.interactiveImageItemActive : classes.interactiveImageItem}
        style={{
          
        }}
      >
        <img
          src={item.image_content}
          width={width > 5 ? width : 5}
          style={{
            opacity: 0,
            margin: 6,
            pointerEvents: 'none'
          }}
          ref={imageRef}
        />
        {active && 
        <div
          className={classes.closeBox}
          style={{
            position: 'absolute',
            top: -6,
            left: -6,
            width: 12,
            height: 12,
            cursor: 'pointer',
            backgroundColor: 'red',
          }}
          onClick={(e) => {
            if (active) {
              if (onRemove && window.confirm('Remove this item?')) onRemove();
            }
          }}
        ><CloseIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
        {active &&
        <div
          className={classes.resizeBox}
          style={{
            position: 'absolute',
            bottom: -6,
            left: -6,
            width: 12,
            height: 12,
            cursor: 'nesw-resize',
          }}
          onMouseDown={(e) => {
            if (active) {
              setDragStartPos([e.clientX, e.clientY]);
              setPointerPos([e.clientX, e.clientY]);
              setIsResizing(true);
              setResizingDirection(-1);
              e.stopPropagation();
              console.log('start resizing');
            }
          }}
          onTouchStart={(e) => {
            if (active) {
              if (!e.touches.length) return;
    
              const t = e.touches[0];
    
              (window as any)._touchX = t.clientX;
              (window as any)._touchY = t.clientY;
              
              setDragStartPos([t.clientX, t.clientY]);
              setPointerPos([t.clientX, t.clientY]);
              setIsResizing(true);
              setResizingDirection(-1);
              e.stopPropagation();
              console.log('touch: start resizing');
            }
          }}
        ><ResizeIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
        {active &&
        <div
          className={classes.resizeBox}
          style={{
            position: 'absolute',
            bottom: -6,
            right: -6,
            width: 12,
            height: 12,
            cursor: 'nwse-resize',
          }}
          onMouseDown={(e) => {
            if (active) {
              setDragStartPos([e.clientX, e.clientY]);
              setPointerPos([e.clientX, e.clientY]);
              setIsResizing(true);
              setResizingDirection(1);
              e.stopPropagation();
              console.log('start resizing');
            }
          }}
          onTouchStart={(e) => {
            if (active) {
              if (!e.touches.length) return;
    
              const t = e.touches[0];
    
              (window as any)._touchX = t.clientX;
              (window as any)._touchY = t.clientY;
              
              setDragStartPos([t.clientX, t.clientY]);
              setPointerPos([t.clientX, t.clientY]);
              setIsResizing(true);
              setResizingDirection(1);
              e.stopPropagation();
              console.log('touch: start resizing');
            }
          }}
        ><ResizeIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
        {active &&
        <div
          className={classes.rotateBox}
          style={{
            position: 'absolute',
            top: -6,
            right: -6,
            width: 12,
            height: 12,
            cursor: 'grabbing'
          }}
          onMouseDown={(e) => {
            if (active) {
              setDragStartPos([e.clientX, e.clientY]);
              setPointerPos([e.clientX, e.clientY]);
              setIsRotating(true);
              setRotatingSideX(1);
              setRotatingSideY(1);
              e.stopPropagation();
              console.log('start rotating');
            }
          }}
          onTouchStart={(e) => {
            if (active) {
              if (!e.touches.length) return;
    
              const t = e.touches[0];
    
              (window as any)._touchX = t.clientX;
              (window as any)._touchY = t.clientY;
              
              setDragStartPos([t.clientX, t.clientY]);
              setPointerPos([t.clientX, t.clientY]);
              setIsRotating(true);
              setRotatingSideX(1);
              setRotatingSideY(1);
              e.stopPropagation();
              console.log('touch: start rotating');
            }
          }}
        ><RotateIcon style={{display: 'block', fontSize: 12, color: '#fff'}} /></div>}
      </div>
    </div>
  );
}



interface InteractiveOverlayProps {
  width: number;
  height: number;
  image: string;
  overlayShadowImage?: string;
  siteSetting: SiteSetting;
  design: Design;
  selectedPanel: string;
  selectedSubPanel: string;
  onPanelChanged: (panel: string, subpanel: string) => void;
  onDesignChanged: (design: Design) => void;
}

function InteractiveOverlay(props: InteractiveOverlayProps) {
  const classes = useStyles();
  const imgElement = useRef(null as HTMLImageElement|null);

  const offsetTop = imgElement.current?.offsetTop;
  const offsetLeft = imgElement.current?.offsetLeft;
  const trueWidth = props.siteSetting.canvas_width;
  const trueHeight = props.siteSetting.canvas_height;
  const zoom = (imgElement.current?.width ? imgElement.current?.width : trueWidth) / trueWidth;

  const images: any[] = [];
  const texts: any[] = [];

  if (props.design.template_data) {
    let cw = 1500;
    let ch = 1500;

    let templateTexts: any[] = [];
    if (props.design.template_data.template_texts) {
      props.design.template_data.template_texts.forEach((item) => {
        templateTexts.push(item)
      });
    }

    [1,2,3,4,5,6].forEach((i) => {
      let name = `text_${i}`;
        let content = (props.design as any)[`text_${i}`];
        if (!content) return;
        let alreadyUsed = false;
        templateTexts.forEach((position) => {
          if (position.text_type == name) alreadyUsed = true;
        });
        
        if (!alreadyUsed) {
          templateTexts.push({
            "x": cw / 2,
            "y": ch / 2,
            "font_size": 80,
            "text_type": name,
            "fill_color": "text_color",
            "line_width": 8,
            "text_align": "center",
            "stroke_color": "text_stroke_color"
          });
        }
    });

    templateTexts.forEach((item, i) => {
      texts.push(getFullTextDetail(props.design, item));
    });

    let templateLogos: any[] = [];
    if (props.design.template_data.template_logos) {
      props.design.template_data.template_logos.forEach((position, i) => {
        if (position.empty) return;
        let imageUrl = props.design.logo_1;
        if (position.image == 'logo-1') imageUrl = props.design.logo_1;
        if (position.image == 'logo-2') imageUrl = props.design.logo_2;
        if (position.image == 'logo-3') imageUrl = props.design.logo_3;
        if (position.image == 'logo-4') imageUrl = props.design.logo_4;
        if (position.image == 'logo-5') imageUrl = props.design.logo_5;

        if (!imageUrl) return;

        templateLogos.push(position);
      });
    }

    [1,2,3,4,5].forEach((i) => {
      let name = `logo-${i}`;
      let logo = (props.design as any)[`logo_${i}`];
      if (!logo) return;
      let alreadyUsed = false;
      templateLogos.forEach((position) => {
        if (position.image == name) alreadyUsed = true;
      });
      
      if (!alreadyUsed) {
        templateLogos.push({
          "w": cw / 6,
          "x": cw / 2 - (cw / 12),
          "y": ch / 2 - (ch / 12),
          "image": name
        });
      }
    });

    templateLogos.forEach((item, i) => {
      images.push(getFullIconDetail(props.design, item));
    });
  }

  let designNameText = {
    x: props.siteSetting.canvas_width / 2,
    y: 160 * zoom,
    text_align: 'center',
    text: (props.design.design_name ? props.design.design_name : '[Your Design]').toUpperCase(),
    font_name: 'Myriad Pro Black',
    font_size: (window as any)._design_name_font_size ? (window as any)._design_name_font_size : 100,
  }

  // console.log(texts);
  // console.log(images);

  return (
    <div className={classes.renderedImageContainer} style={{width: props.width, height: props.height}}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
        onClick={() => {
          // props.onPanelChanged('designName', '')
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          top: imgElement.current?.offsetTop,
          left: imgElement.current?.offsetLeft,
        }}
      >
        <div style={{
          width: trueWidth * zoom,
          height: trueHeight * zoom,
        }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
            onClick={() => {
              // props.onPanelChanged('designName', '')
            }}
          ></div>
          {texts.map((item, i) => {
            return (
              <InteractiveTextItem
                key={`text-${i}`}
                item={item}
                active={(props.selectedPanel==='text') && (props.selectedSubPanel===item.text_type)}
                onClick={() => {
                  props.onPanelChanged('text', item.text_type)
                }}
                onPositionChanged={(position) => {
                  let design = Object.assign({}, props.design);
                  if (item.text_type == 'text_1') design.text_position_1 = position;
                  if (item.text_type == 'text_2') design.text_position_2 = position;
                  if (item.text_type == 'text_3') design.text_position_3 = position;
                  if (item.text_type == 'text_4') design.text_position_4 = position;
                  if (item.text_type == 'text_5') design.text_position_5 = position;
                  if (item.text_type == 'text_6') design.text_position_6 = position;
                  props.onDesignChanged(design);
                }}
                onRemove={() => {
                  let design = Object.assign({}, props.design);
                  if (item.text_type == 'text_1') design.text_1 = '';
                  if (item.text_type == 'text_2') design.text_2 = '';
                  if (item.text_type == 'text_3') design.text_3 = '';
                  if (item.text_type == 'text_4') design.text_4 = '';
                  if (item.text_type == 'text_5') design.text_5 = '';
                  if (item.text_type == 'text_6') design.text_6 = '';
                  props.onDesignChanged(design);
                }}
                zoom={zoom}
              />
            );
          })}
          {images.map((item, i) => {
            return (
              <InteractiveImageItem
                key={`logo-${i}`}
                item={item}
                active={(props.selectedPanel==='logo') && (props.selectedSubPanel===item.image)}
                onClick={() => {
                  props.onPanelChanged('logo', item.image)
                }}
                onPositionChanged={(position) => {
                  let design = Object.assign({}, props.design);
                  if (item.image == 'logo-1') design.logo_position_1 = position;
                  if (item.image == 'logo-2') design.logo_position_2 = position;
                  if (item.image == 'logo-3') design.logo_position_3 = position;
                  if (item.image == 'logo-4') design.logo_position_4 = position;
                  if (item.image == 'logo-5') design.logo_position_5 = position;
                  props.onDesignChanged(design);
                }}
                onRemove={() => {
                  let design = Object.assign({}, props.design);
                  if (item.image == 'logo-1') {
                    design.logo_1 = undefined;
                    design.logo_1_file = undefined;
                  }
                  if (item.image == 'logo-2') {
                    design.logo_2 = undefined;
                    design.logo_2_file = undefined;
                  }
                  if (item.image == 'logo-3') {
                    design.logo_3 = undefined;
                    design.logo_3_file = undefined;
                  }
                  if (item.image == 'logo-4') {
                    design.logo_4 = undefined;
                    design.logo_4_file = undefined;
                  }
                  if (item.image == 'logo-5') {
                    design.logo_5 = undefined;
                    design.logo_5_file = undefined;
                  }
                  props.onDesignChanged(design);
                }}
                zoom={zoom}
              />
            );
          })}
          <InteractiveTextItem
            key={`design-name-text`}
            item={designNameText}
            active={false}
            onClick={() => {
              props.onPanelChanged('designName', '')
            }}
            zoom={zoom}
          />
        </div>
      </div>
      <img
        ref={imgElement}
        src={props.image}
        alt=""
        className={classes.renderedImage}
      />
      {props.overlayShadowImage &&
        <img
          src={props.overlayShadowImage}
          alt=""
          className={classes.renderedImage}
          style={{
            position: 'absolute',
            top: (imgElement.current?.offsetTop ? imgElement.current.offsetTop : 0),
            left: imgElement.current?.offsetLeft,
            width: imgElement.current?.width
          }}
        />
      }
    </div>
  )
}

export default InteractiveRenderer;