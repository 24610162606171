import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Grid,
  Collapse,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import { shadeColor } from '../utils/shadeColor';

const useStyles = makeStyles((theme) => ({

  extraEmailsListContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: 10,
    marginBottom: 10,
    '& > div': {
      marginRight: 10,
    }
  },
  extraEmailAddButtonContainer: {
    marginTop: 10,
  }
}));

export default ({shown, onClose, content}: any) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState(false);

  const designId = (window as any)._customizer_is_editor_dirty ? undefined : (window as any)._customizer_design_id;

  return (
    <Dialog onClose={onClose} aria-labelledby="save-dialog-title" open={shown} style={{zIndex: 999999}}>
      <DialogContent>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{'__html': content}} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
